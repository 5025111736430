






























































import Vue from 'vue';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {OVERLAYS} from '@/configurator/components/-utils/overlays';

import {API_BASE} from '@/common/utils/api-url';
import {CORE_DATA_MUTATIONS} from '../store/core-data';

export default Vue.extend({
  components: {
    OpenAR: () => import('@/configurator/components/overlays/ar/OpenAR.vue'),
    Export3D: () => import('@/configurator/components/overlays/Export3D.vue'),
    PartList: () => import('@/configurator/components/overlays/PartList.vue'),
    NoDocking: () => import('@/configurator/components/overlays/NoDocking.vue'),
    VariantWarning: () => import('@/configurator/components/overlays/VariantWarning.vue'),
    SaveDraft: () => import('@/configurator/components/overlays/SaveDraft.vue'),
    ElementDisabled: () => import('@/configurator/components/overlays/ElementDisabled.vue'),
    AddToCart: () => import('@/configurator/components/overlays/AddToCart.vue'),
    Notice: () => import('@/configurator/components/overlays/Notice.vue'),
    OldConfig: () => import('@/configurator/components/overlays/OldConfig.vue'),
    Help: () => import('@/configurator/components/overlays/Help.vue'),
  },

  computed: {
    OVERLAYS(): typeof OVERLAYS {
      return OVERLAYS;
    },
    hasOpenOverlay(): boolean {
      const {overlays} = this.$store.state.uiState;
      for (const state in overlays) {
        if (overlays.hasOwnProperty(state) && overlays[state].open) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    closeOverlays() {
      this.$store.commit(UI_STATE_MUTATIONS.CLOSE_ALL_OVERLAYS);
      if (!this.$store.state.coreData.isEditing) {
        window.parent.postMessage('enable overflow', API_BASE);
      }
    },
    async closeAROverlay() {
      // @ts-ignore
      const wallParam: KernelParameter = {
        key: 'showWall',
      };
      await this.$sdkConnector.setParameter(wallParam, 'show');
      this.closeOverlays();
    },
    closeOldConfigOverlay() {
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_NOT_FOUND, null);
      this.closeOverlays();
    },
  },
});
