// @ts-nocheck
import {Base64Image} from '@roomle/web-sdk/lib/definitions/common-core/src/common-interfaces';
import {KernelPart, KernelPartList} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {RapiConfigurationEnhanced} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {UI_BUTTON} from '@/configurator/embedding/types';
import {Nullable} from '@/common/utils/types';

export interface Price {
  currencySymbol: Nullable<string>;
  price: Nullable<number>;
}

export interface Labels {
  catalogLabel: Nullable<string>;
  label: Nullable<string>;
}

export class ExposedCallbacks {
  /**
   * Returns all necessary params when 'Request Product'/'Checkout' button has pressed
   * @param configurationId id of the current configuration
   * @param image image of the current configuration
   * @param partList the part list with all details, grouped, etc
   * @param price price of the current configuration, either set via setPrice or from Roomle price service
   * @param labels the label of the catalog and the furniture system
   * @param configuration the data returned from the Roomle backend
   */
  public onRequestProduct = (configurationId: string, image: Base64Image, partList: KernelPartList, price: Price, labels: Labels, configuration: RapiConfigurationEnhanced): void => void 0;

  /**
   * fullList fullList (needed for price calculation) of the current configuration
   * @param partList the part list with all details, grouped, etc
   */
  public onPartListUpdate = (partList: KernelPartList, hash: string): void => void 0;

  public onResize = (isDesktop: boolean): void => undefined;

  public onUseFullPage = (): void => undefined;

  public onBackToWebsite = (): void => undefined;

  public onButtonClicked = (name: UI_BUTTON): void => undefined;
}
