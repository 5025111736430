import GoogleAnalytics from '@/common/utils/google-analytics';

export interface Analytics {
  ga: GoogleAnalytics;
}

const AnalyticsPlugin = {
  install(Vue: any, {configuratorId, useTracking}: {configuratorId: string, useTracking: boolean}) {
    Vue.prototype.$analytics = {
      ga: new GoogleAnalytics(configuratorId, useTracking),
    };
  },
};

export default AnalyticsPlugin;
