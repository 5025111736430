












import Vue from 'vue';
import {UI_STATE_ACTIONS, UI_STATE_GETTERS, UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {getHostname, isDemoHostname, throttle} from '@/common/utils/helper';
import {calcIsDesktop} from '@/common/utils/dom';
import {isAndroid, isIOs} from '@/common/utils/user-agent';
import {GA_CATEGORY} from '@/common/utils/google-analytics';
import {EmbeddingSkin, UiInitData} from '@/configurator/embedding/types';
import {prepareSkin} from '@/common/utils/color';
// @ts-ignore
import {blackText, whiteText} from '@/common/styles/Global.module.scss';
import Overlays from '@/configurator/components/Overlays.vue';
import {SDK_MODULES} from '@/configurator/business-logic/sdk-connector';
import {isInIframe} from '@/common/utils/iframe';

import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
import {getGeneralCustomConfig} from './common/utils/custom-config';
import {getRequestData} from './common/utils/get-request-data';
import {API_BASE} from '@/common/utils/api-url';
import {CORE_DATA_MUTATIONS, CORE_DATA_ACTIONS} from './configurator/store/core-data';

export default Vue.extend({

  components: {
    ConfiguratorMain: () => import('@/configurator/components/ConfiguratorMain.vue'),
    ViewerMain: () => import('@/configurator/components/ViewerMain.vue'),
    CookieBanner: () => import('@/configurator/components/CookieBanner.vue'),
    Overlays,
  },

  data() {
    return {
      acceptedCookies: true,
    };
  },

  async mounted() {
    const id = this.initData.id || null;
    this.acceptedCookies = document.cookie.split(';').some((cookie) => /cookieAccepted/ig.test(cookie));
    if (id) {
      await this.$sdkConnector.loadObject(id);
    } else {
      // this should be refactored with https://roomle.atlassian.net/browse/RML-44
      const isViewer = this.$store.state.uiState.initData?.viewer;
      const module = isViewer ? SDK_MODULES.VIEWER : SDK_MODULES.CONFIGURATOR;
      this.$sdkConnector.initCanvasElement(module);
    }

    // get parent url, because document.referrer is not always possible
    window.addEventListener('message', (e) => {
      if (
        e.origin !== 'https://app-v2.vallone.localhost'
        && e.origin !== 'https://develop.vallone.de'
        && e.origin !== 'https://www.vallone.de'
      ) {
        return;
      } else {
        if (e.data.action === 'parent url' && e.data.action) {
          this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_PARENT_URL, e.data.url);
        }
      }
    });

    window.parent.postMessage('get parent url', API_BASE);

    // disable zoom on scene scroll, little bit hacky
    if (window.innerWidth >= 1024) {
      // @ts-ignore
      (await this.$sdkConnector.api as RoomleConfigurator)._sceneManager._cameraControl._inputManager._onMouseWheel = () => {
        // console.log('!!!!WARNING WE CHANGED A PRIVATE METHOD!!!!');
        return;
      };
    }

    // change initial camera position in integrated mode
    if (this.getLook() === 'integrated') {
      (await this.$sdkConnector.api as RoomleConfigurator).moveCamera({
        yaw: -0.785,
        pitch: 0.8,
        distance: window.innerWidth < 1024 ? 3 : 2,
        targetX: 0, targetY: 0.6, targetZ: 0,
        blockOtherTweens: true,
      });
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 2000);
    } else {
      if (window.innerWidth < 1024) {
        window.parent.postMessage('just disable overflow', API_BASE);
      }
    }

    // check if every needed article is there
    const requestData = await getRequestData(this.$sdkConnector, this.$store, 'null', false);
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    };
    // @ts-ignore
    const response = await fetch(`${API_BASE}/api/v1/roomle/calc-price`, requestOptions);
    const json = await response.json();

    // check if every needed material is there
    const materialsResponse = await fetch('https://api.roomle.com/v2/catalogs/vallone/materials');
    const materialsJson = await materialsResponse.json();
    const materials = materialsJson.materials.map((material: any) => ({value: material.id, status: material.visibilityStatus}));

    const notFoundMaterials: Array<{ item: any; param: any; }> = [];

    this.$store.state.coreData.partList.fullList.forEach((item: any) => {
      item.parameters.filter((param: any) => {
        if (param.type === 'Material' && !materials.filter((material: any) => material.value === param.value).length) {
          notFoundMaterials.push({item, param});
        }
      });
    });

    if (json.data.notFoundArticles.length || notFoundMaterials.length) {
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_NOT_FOUND, {
        notFoundArticles: json.data.notFoundArticles,
        notFoundMaterials,
      });
    }
    this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_TAX_RATE, {
      taxRate: json.data.taxRate,
    });
    this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_SHOP_AVAILABLE, {
      isShopAvailable: json.data.isShopAvailable,
    });
    this.$store.dispatch(CORE_DATA_ACTIONS.UPDATE_TAX_RATE, json.data.taxRate);
    this.$store.dispatch(CORE_DATA_ACTIONS.UPDATE_IS_SHOP_AVAILABLE, json.data.isShopAvailable);
  },

  computed: {
    initData(): UiInitData {
      return this.$store.state.uiState.initData;
    },
    showCookieBanner(): boolean {
      const hostname = getHostname();
      return !this.acceptedCookies && (hostname && isDemoHostname(hostname) ? true : false) && !isInIframe();
    },
    lastRequestedId(): string {
      return this.$store.state.uiState.lastRequestedId || this.$store.state.uiState.initData?.id;
    },
  },

  watch: {
    initData() {
      if (!this.initData.skin) {
        return;
      }
      prepareSkin(this.initData, blackText, whiteText);

      for (const cssProperty in this.initData.skin) {
        if (this.initData.skin.hasOwnProperty(cssProperty)) {
          document.documentElement.style.setProperty('--' + cssProperty, this.initData.skin[cssProperty as keyof EmbeddingSkin] as string);
        }
      }
    },
  },

  methods: {
    setViewModes() {
      if (this.$store.state.uiState.userInitiatedSearch) {
        // do not change the view mode while a user is searching
        // this happens on Android when the keyboard slides up
        // and therefore triggers a resize event. This early return
        // solves: https://roomle.atlassian.net/browse/DM-534
        return;
      }
      const isLandscape = window.innerWidth > window.innerHeight;
      this.$store.commit(UI_STATE_MUTATIONS.SET_IS_LANDSCAPE, isLandscape);
      const isDesktop = calcIsDesktop();
      this.$store.dispatch(UI_STATE_ACTIONS.SELECT_VIEW_MODE, isDesktop);
      if (!this.$store.getters[UI_STATE_GETTERS.USE_REGULAR_LAYOUT] && !isAndroid() && !isIOs()) {
        this.$analytics.ga.trackEvent('Mobile Landscape but not Android or iOS', GA_CATEGORY.TRACKING, navigator.userAgent);
      }
      this.$embeddingCallbacks.onResize(isDesktop);
    },
    updateCookies() {
      this.acceptedCookies = document.cookie.split(';').some((cookie) => /cookieAccepted/ig.test(cookie));
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
  },

  beforeMount() {
    this.setViewModes();
    this.setViewModes = throttle(this.setViewModes).bind(this);
    window.addEventListener('resize', this.setViewModes);
  },

  destroyed() {
    window.removeEventListener('resize', this.setViewModes);
  },

});
