type ResolveFunction<T> = (value?: T | PromiseLike<T>) => void;
type RejectFunction = (reason?: any) => void;
interface PromiseCallback<T> {
  resolve: ResolveFunction<T>;
  reject: RejectFunction;
}

const SCRIPT_CACHE: {[key: string]: boolean} = {};
const SCRIPT_ID_CACHE: {[key: string]: boolean} = {};

const SCRIPT_WAITER: {[key: string]: Array<PromiseCallback<void>>} = {};

const attachScript = (url: string, options: {id: string}, resolve: ResolveFunction<void>, reject: RejectFunction) => {

  if (SCRIPT_CACHE[url]) {
    return resolve();
  }

  if (options.id && SCRIPT_ID_CACHE[options.id]) {
    return reject('There is already a script with ID "' + options.id + '"');
  }

  if (SCRIPT_WAITER[options.id]) {
    SCRIPT_WAITER[options.id].push({resolve, reject});
    return;
  }

  const element = document.createElement('script');
  element.async = false;
  element.src = url;
  element.id = options.id;
  element.onload = () => {
    SCRIPT_CACHE[url] = true;
    SCRIPT_ID_CACHE[options.id] = true;
    SCRIPT_WAITER[options.id].forEach((callback) => callback.resolve()); // tslint-disable-line no-shadow
  };
  element.onerror = (error) => {
    SCRIPT_WAITER[options.id].forEach((callback) => callback.reject(error));
  };
  SCRIPT_WAITER[options.id] = [];
  SCRIPT_WAITER[options.id].push({resolve, reject});
  document.head.appendChild(element);
};

export default class ScriptLoader {
  public static fetch(url: string, options: {id: string}): Promise<void> {
    return new Promise((resolve: ResolveFunction<void>, reject: RejectFunction) => {
      attachScript(url, options, resolve, reject);
    });
  }
}
