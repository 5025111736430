import {Analytics} from '@/common/plugins/analytics';
import {GA_CATEGORY} from '@/common/utils/google-analytics';
import {UiInitData} from '@/configurator/embedding/types';

const logDeprecation = (version: string, label: string, message: string, analytics: Analytics) => {
  console.warn('DEPRECATION:', 'this deprecation will be breaking after: "' + version + '"');
  console.warn('DEPRECATION:', message);
  console.warn('DEPRECATION:', 'please check our changelog when you upgrade @roomle/embedding-lib');
  console.warn('DEPRECATION:', 'https://www.roomle.com/t/docs/web/ui/CHANGELOG.html');
  analytics.ga.trackEvent(label, GA_CATEGORY.DEPRECATION, 'Breaking after version: "' + version + '"');
};

export const checkDeprecations = (inIframe: boolean, uiInitData: UiInitData, analytics: Analytics) => {
  if (inIframe && !uiInitData.featureFlags?.realPartList) {
    logDeprecation('3.12.0-alpha.1',
      'partlist',
      'after an upgrade the whole partlist object will be returned',
      analytics,
    );
  }
};
