import {getGeneralCustomConfig} from '@/common/utils/custom-config';
import {SdkConnector} from '@/configurator/business-logic/sdk-connector';
import {createGroupParameter, sortPartListGroups} from './create-part-list-content';

export const getRequestData = async (sdkConnector: SdkConnector, store: any, partListStringStart: string, createImage = true) => {
  await sdkConnector.waitForLoad();
  let image = '';
  let currentConfiguration = null;
  if (createImage) {
    const configurator = await sdkConnector.api;
    const res = await configurator.preparePerspectiveImage();
    image = res.image;
    currentConfiguration = await sdkConnector.saveCurrentConfiguration();
  }
  const perspectiveImage = image;

  const customConfig = getGeneralCustomConfig();

  const configurationId = store.state.uiState.initData.id.split(':');
  const productKey =  store.state.uiState.initData.model;

  const requestData = {
    title: customConfig.headline,
    webTeaser: customConfig.partListDescription ? customConfig.partListdescription : null,
    thumbnail: perspectiveImage,
    baseUrl: '',
    configurationId: currentConfiguration?.id ? `${currentConfiguration.id}&model=${productKey}` : null,
    productKey: productKey,
    articles: [],
    attributeSKU: customConfig.dataSKU ? customConfig.dataSKU : null,
    count: store.state.coreData.count,
    field_name: customConfig.dataSKU ? customConfig.dataSKU : null,
    source: '',
    partListContent: await createGroupParameter(sortPartListGroups(store), store, partListStringStart),
    allParams: store.state.coreData.groups.map((group: any) => group.parameters.map((parameter: any) => ({key: parameter.key, value: parameter.value}))).flat(),
  };

  const simplerPartListContent = requestData.partListContent.map((group: any) => {
    return {
      groupValue: group.groupValue,
      params: group.params,
      firstArticleSKU: group.parts[0]?.articleNr,
    };
  });

  store.state.coreData.partList.fullList.forEach((articleItem: any) => {
    const article: {
      title: string,
      articleNr: string,
      count: number,
      parameters: any[],
    } = {
      title: articleItem.label,
      articleNr: articleItem.articleNr,
      count: articleItem.count,
      parameters: [],
    };

    articleItem.parameters.forEach((parameterItem: any) => {
      article.parameters.push({
        key: parameterItem.key,
        label: parameterItem.label,
        value: parameterItem.value,
        valueLabel: parameterItem.valueLabel,
        sort: parameterItem.sort,
        type: parameterItem.type,
      });
    });

    const partListGroup = simplerPartListContent.filter((group: any) => group.firstArticleSKU === article.articleNr)[0];
    if (partListGroup) {
      // @ts-ignore
      article.group_headline = partListGroup.groupValue;
      // @ts-ignore
      article.group_parameter = partListGroup.params;
    }

    // @ts-ignore
    requestData.articles.push(article);
  });

  requestData.articles.sort((a: any, b: any) => {
    const sortParamA = a.parameters.filter((param: any) => param.key === 'partListSort')[0];
    const sortParamB = b.parameters.filter((param: any) => param.key === 'partListSort')[0];

    if (sortParamA?.value < sortParamB?.value) {
      return -1;
    } else if (sortParamA?.value > sortParamB?.value) {
      return 1;
    } else {
      return 0;
    }
  });

  return requestData;
};
