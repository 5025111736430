import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import {CORE_STATE_ACTIONS, CORE_STATE_MUTATIONS, coreState, CoreState} from '@/configurator/store/core-state';
import {CORE_DATA_ACTIONS, CORE_DATA_MUTATIONS, coreData, CoreData} from '@/configurator/store/core-data';
import {UI_STATE_ACTIONS, UI_STATE_MUTATIONS, uiState, UiState} from '@/configurator/store/ui-state';

Vue.use(Vuex);

export const MUTATIONS = {
  ...CORE_STATE_MUTATIONS,
  ...CORE_DATA_MUTATIONS,
  ...UI_STATE_MUTATIONS,
};

export const ACTIONS = {
  ...CORE_STATE_ACTIONS,
  ...CORE_DATA_ACTIONS,
  ...UI_STATE_ACTIONS,
};

export interface StoreState {
  coreState: CoreState;
  coreData: CoreData;
  uiState: UiState;
}

const store: StoreOptions<StoreState> = {
  modules: {
    coreState,
    coreData,
    uiState,
  },
};

export default new Vuex.Store<StoreState>(store);
