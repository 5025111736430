let apiBaseUrl = '';
let roomleUrl = '';
switch (process.env.NODE_ENV) {
  case 'development':
    apiBaseUrl = 'https://app-v2.vallone.localhost';
    roomleUrl = 'https://roomle-new.vallone.localhost:4040';
    break;

  case 'staging':
    apiBaseUrl = 'https://develop.vallone.de';
    roomleUrl = 'https://configurator.develop.vallone.de';
    break;


  case 'production':
  default:
    apiBaseUrl = 'https://www.vallone.de';
    roomleUrl = 'https://configurator.vallone.de';
    break;
}

const urlParams = new URLSearchParams(window.location.search);
const myLocale = urlParams.get('locale');

let apiBaseUrlLangExt = '';
if (myLocale && myLocale !== 'de') {
  apiBaseUrlLangExt = '/' + myLocale;
}

export const API_BASE = apiBaseUrl + apiBaseUrlLangExt;
export const ROOMLE_BASE = roomleUrl;
