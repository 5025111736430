
/* TO DOs in Google Analytics Admin UI

 To track the Hit ID, Hit Time, and Hit Type and custom dimensions, first create them in Google Analytics
 and set their scope to "Hit"

 Once you've created the custom metrics in Google Analytics
 (and set their scope to "Hit" and their formatting type to "Integer")
 this setup is ready to go. You need to do this in every GA property

 For more details see: https://philipwalton.com/articles/the-google-analytics-setup-i-use-on-every-site-i-build/

 */

import {uuid} from '@/common/utils/helper';
import {Nullable} from '@/common/utils/types';
import ScriptLoader from '@/common/utils/script-loader';
import {isInIframe} from '@/common/utils/iframe';

export const GA_DIMENSIONS: {[key: string]: string} = {
  CLIENT_ID: 'clientId',
  WINDOW_ID: 'windowId',
  HIT_ID: 'hitId',
  HIT_TIME: 'hitTime',
  HIT_TYPE: 'hitType',
  HIT_SOURCE: 'hitSource',
  VISIBILITY_STATE: 'visibilityState',
  INIT_ID: 'initId',
  CONFIGURATOR_ID: 'configuratorId',
};

const GA_DIMENSIONS_ORDER = [
    /*01*/ GA_DIMENSIONS.CLIENT_ID,
    /*02*/ GA_DIMENSIONS.WINDOW_ID,
    /*03*/ GA_DIMENSIONS.HIT_ID,
    /*04*/ GA_DIMENSIONS.HIT_TIME,
    /*05*/ GA_DIMENSIONS.HIT_TYPE,
    /*06*/ GA_DIMENSIONS.HIT_SOURCE,
    /*07*/ GA_DIMENSIONS.VISIBILITY_STATE,
    /*08*/ GA_DIMENSIONS.INIT_ID,
    /*09*/ GA_DIMENSIONS.CONFIGURATOR_ID,
];

export const GA_METRICS = {
  RESPONSE_END_TIME: 'responseEndTime',
  DOM_LOAD_TIME: 'domLoadTime',
  WINDOW_LOAD_TIME: 'windowLoadTime',
  TIME_TO_FIRST_BTYE: 'timeToFirstByte',
  KERNEL_IS_READY: 'kernelIsReady',
  INIT_LOADING_SCREEN_CLOSE: 'initLoadingScreenClose',
};

const GA_METRICS_ORDER = [
  /*01*/ GA_METRICS.RESPONSE_END_TIME,
  /*02*/ GA_METRICS.DOM_LOAD_TIME,
  /*03*/ GA_METRICS.WINDOW_LOAD_TIME,
  /*04*/ GA_METRICS.TIME_TO_FIRST_BTYE,
  /*05*/ GA_METRICS.KERNEL_IS_READY,
  /*06*/ GA_METRICS.INIT_LOADING_SCREEN_CLOSE,
];

// Probably we could add const enums here
// but we need to investigate: https://roomle.atlassian.net/browse/CONF-238
enum GA_CUSTOM {
  DIMENSION = 'dimension',
  METRIC = 'metric',
}

enum GA_HIT_TYPE {
  EVENT = 'event',
}

enum GA_ACTION_TYPE {
  EXCEPTION = 'exception',
  TIMING = 'timing_complete',
}

enum CUSTOM_ACTION_TYPE {
  TRACK_TIMING = 'track_timing',
}

const CONTENT_ERROR_PREFIX = 'CONTENT_ERROR: ';

const DEBUG_TRACKING_LOCALLY = false;

export const GA_NULL_VALUE = '(not set)';

export enum GA_CATEGORY {
  ERROR = 'Error',
  NAVIGATION_TIMING = 'Navigation Timing',
  TIMING = 'Timing',
  INTERACTION = 'Interaction',
  TRACKING = 'Tracking',
  DEPRECATION = 'Deprecation',
}

export type GoogleAnalyticsCallback = (...args: any[]) => void;

export const MAX_QUEUE_LENGTH = 1500;

export default class GoogleAnalytics {

  public history: IArguments[] = [];

  private _settings: {[key: string]: any};
  private _queue: IArguments[] = [];
  private _trackingId: string = process.env.VUE_APP_GA_ID as string;
  private _useTracking: boolean;
  private _callbacks: GoogleAnalyticsCallback[] = [];
  private _gaReady: boolean = false;

  private get _gtag() {
    if (!this._useTracking) {
      return (...args: any[]) => (DEBUG_TRACKING_LOCALLY) ? console.log('GA-Tracking: ', ...args) : null;
    }
    return (window as any).gtag;
  }

  // currently we set useTracking to true if we do not provide a value
  // for www.roomle.com it is determined by the build flags and the
  // embedding webshop has to deactivate Google Analytics with init data
  // we need to figure out if we can set useTracking to false on default
  // but this will happen here: https://roomle.atlassian.net/browse/RML-609
  constructor(configuratorId: string, useTracking: boolean = true) {
    this._useTracking = useTracking;
    const customMap = {};
    this._setObjectProperties(GA_DIMENSIONS, customMap, GA_CUSTOM.DIMENSION, GA_DIMENSIONS_ORDER);
    this._setObjectProperties(GA_METRICS, customMap, GA_CUSTOM.METRIC, GA_METRICS_ORDER);
    const settings: {[key: string]: any} = {custom_map: customMap};

    if ((navigator as any).sendBeacon) {
      settings.transport_type = 'beacon';
    }

    Object.keys(GA_DIMENSIONS).forEach((key) => settings[GA_DIMENSIONS[key]] = GA_NULL_VALUE);
    delete settings[GA_DIMENSIONS.CLIENT_ID]; // USE GTAG.JS DEFAULT, see: https://bit.ly/2Fg7fws
    settings[GA_DIMENSIONS.WINDOW_ID] = uuid();
    settings[GA_DIMENSIONS.CONFIGURATOR_ID] = configuratorId;
    settings[GA_DIMENSIONS.VISIBILITY_STATE] = document.visibilityState !== undefined ? document.visibilityState : GA_NULL_VALUE;
    settings.anonymize_ip = true; // also need to add it here, otherwise not all requests are anonymized, this is due to tagmanager vs analytics
    if ((window as any).ga) {
      /* tracker: any because it is something from GA */
      (window as any).ga((tracker: any) => {
        const buildTaskKey = 'buildHitTask';
        const originalBuildHitTask = tracker.get(buildTaskKey);
        const customMapSettingsSettings = settings.custom_map;
        /* model: any because it is something from GA */
        tracker.set(buildTaskKey, (model: any) => {
          const qt = (model.get('queueTime') as number) || 0;
          model.set(this._findDimension(GA_DIMENSIONS.HIT_TIME, customMapSettingsSettings), String(new Date().getTime() - qt), true);
          model.set(this._findDimension(GA_DIMENSIONS.HIT_ID, customMapSettingsSettings), uuid(), true);
          model.set(this._findDimension(GA_DIMENSIONS.HIT_TYPE, customMapSettingsSettings), model.get('hitType'), true);
          model.set(this._findDimension(GA_DIMENSIONS.VISIBILITY_STATE, customMapSettingsSettings), document.visibilityState, true);
          originalBuildHitTask(model);
        });
      });
    }

    this._settings = settings;
    this._setSettings();

    this._trackErrors();
    this._sendNavigationTimingMetrics();
    this._execCommand('config', this._trackingId, {
      page_title: 'homepage',
      page_path: window.location.pathname,
      page_location: window.location.href,
      anonymize_ip: true,
    });

    if (this._useTracking) {
      this.giveConsent();
    }

  }

  public giveConsent() {
    this._useTracking = true;
    // load the script when we have time, in the meanwhile
    // everything is saved into a queue
    (window as any).requestIdleCallback(() => this._loadLibScript().then(() => {
      this._gaReady = true;
      this._flushQueue();
    }));
  }

  public trackEvent(action: CUSTOM_ACTION_TYPE | GA_ACTION_TYPE | string, category: GA_CATEGORY, label: string, value: Nullable<number> = null, fieldsObject: object = {}) {

    const data: {[key: string]: any} = {event_category: category, event_label: label};

    if (typeof value === 'number') {
      data.value = value;
    }
    this._send(GA_HIT_TYPE.EVENT, action, Object.assign(data, fieldsObject));
  }

  public trackTiming(category: GA_CATEGORY, label: any, value: number, fieldsObject: object = {}) {
    this.trackEvent(GA_ACTION_TYPE.TIMING, category, label, value, {name: label});
    this.trackEvent(CUSTOM_ACTION_TYPE.TRACK_TIMING, category, label, value, fieldsObject);
  }

  public setDimension(dimension: string, value: any) {
    this._settings[dimension] = value;
    this._execCommand('config', this._trackingId, this._settings);
  }

  public setDimensions(dimensions: {[key: string]: any}) {
    for (const key in dimensions) {
      if (dimensions.hasOwnProperty(key)) {
        this.setDimension(key, dimensions[key]);
      }
    }
  }

  public trackError(message: string, specialErrorName: Nullable<string> = null) {
    // "as any" because --> https://stackoverflow.com/a/40936309/1280401
    const errorEvent = new (ErrorEvent as any)('TrackError', {
      error: new Error(message),
      message,
      lineno: -1,
      filename: GA_NULL_VALUE,
    });
    if (specialErrorName) {
      try {
        Object.defineProperty(errorEvent.error, 'name', {value: specialErrorName});
      } catch (e) {
        errorEvent.error = e;
      }
    }
    this._trackErrorEvent(errorEvent);
  }

  public trackContentError(message: string) {
    this.trackError(CONTENT_ERROR_PREFIX + message, 'ContentError');
  }

  public addCallback(listener: GoogleAnalyticsCallback) {
    if (this._callbacks.includes(listener)) {
      return;
    }
    this._callbacks.push(listener);
  }

  public removeUiCallback(listener: GoogleAnalyticsCallback) {
    const index = this._callbacks.indexOf(listener);
    if (index === -1) {
      return;
    }
    this._callbacks.splice(index, 1);
  }

  public cleanUpHistory() {
    this.history = [];
  }

  public trackWrongDomain() {
    this.trackError('Domain error', 'DOMAIN_ERROR');
  }

  public trackNoActivePackage() {
    this.trackError('No active package', 'PACKAGE_ERROR');
  }

  private _execCommand(...args: any[]) {
    this._callbacks.forEach((callback) => callback(...args));
    this._gtag(...args);
  }

  private _loadLibScript() {
    if (this._useTracking) {
      return ScriptLoader.fetch('https://www.googletagmanager.com/gtag/js?id=' + this._trackingId, {id: 'tracking'});
    }
    return Promise.resolve();
  }

  private _send(..._args: any[]) {
    this._queue.push(arguments);
    if (isInIframe()) {
      this.history.push(arguments);
    }
    // if the queues are becoming too large we
    // need to clear them otherwise we get memory
    // problems. We trade tracking accurency against
    // runtime performance here but performance is
    // more important than super clean tracking
    if (this._queue.length > MAX_QUEUE_LENGTH) {
      this._queue = [];
    }
    if (this.history.length > MAX_QUEUE_LENGTH) {
      this.cleanUpHistory();
    }

    // Only send GA trackings when we have time
    // otherwise GA interferse with our rendering performance
    (window as any).requestIdleCallback(() => this._flushQueue());
  }

  private _flushQueue() {
    if (!this._gaReady && !DEBUG_TRACKING_LOCALLY) {
      return;
    }
    this._queue.forEach((queuedArgs) => this._execCommand(...queuedArgs));
    this._queue = [];
  }

  private _setObjectProperties(input: {[key: string]: string}, output: {[key: string]: string}, gaKey: GA_CUSTOM, order: string[]) {
    const map: {[key: string]: number} = {};
    order.forEach((value, index) => map[value] = index + 1);
    Object.keys(input).forEach((key) => output[gaKey + map[input[key]]] = input[key]);
  }

  private _trackException(description: string, fatal: boolean = false) {
    this._send(GA_HIT_TYPE.EVENT, GA_ACTION_TYPE.EXCEPTION, {
      description,
      fatal,
    });
  }

  private _trackErrors() {
    const loadErrorEvents: ErrorEvent[] = (window as any).__e?.q?.length ? (window as any).__e.q : [];
    for (const event of loadErrorEvents) {
      this._trackErrorEvent(event);
    }
    window.removeEventListener('error', (window as any).__e);
    window.addEventListener('error', this._trackErrorEvent.bind(this));
  }

  private _trackErrorEvent(error: ErrorEvent) {
    const err = error.error || {
      message: `${error.message} (${error.lineno}:${error.colno})`,
    };
    const name = err.name || '(no error name)';
    const label = `${err.message}\n${err.stack || '(no stack trace)'}`;
    this.trackEvent(name, GA_CATEGORY.ERROR, label, undefined, {
      nonInteraction: true,
    });

    this._trackException(err.message);

  }

  private _setSettings() {
    this._execCommand('config', this._trackingId, this._settings);
  }

  private _findDimension(name: string, customMap: {[key: string]: string}) {
    for (const key in customMap) {
      if (customMap.hasOwnProperty(key) && name === customMap[key]) {
        return key;
      }
    }
    return null;
  }

  private _sendNavigationTimingMetrics() {
    // Only track performance in supporting browsers.
    if (!(window.performance && window.performance.timing)) {
      return;
    }

    // If the window hasn't loaded, run this function after the `load` event.
    if (document.readyState !== 'complete') {
      window.addEventListener('load', this._sendNavigationTimingMetrics.bind(this));
      return;
    }

    const nt = performance.timing;
    const navStart = nt.navigationStart;

    const responseEnd = Math.round(nt.responseEnd - navStart);
    const domLoaded = Math.round(nt.domContentLoadedEventStart - navStart);
    const windowLoaded = Math.round(nt.loadEventStart - navStart);
    const timeToFirstByte = Math.round(nt.responseStart - nt.fetchStart);

    // In some edge cases browsers return very obviously incorrect NT values,
    // e.g. 0, negative, or future times. This validates values before sending.
    const allValuesAreValid = (...values: number[]) => values.every((value) => value > 0 && value < 6e6);

    if (allValuesAreValid(responseEnd, domLoaded, windowLoaded)) {
      this.trackTiming(GA_CATEGORY.NAVIGATION_TIMING, GA_NULL_VALUE, Math.round((new Date()).getTime() - navStart), {
        nonInteraction: true,
        [GA_METRICS.RESPONSE_END_TIME]: responseEnd,
        [GA_METRICS.DOM_LOAD_TIME]: domLoaded,
        [GA_METRICS.WINDOW_LOAD_TIME]: windowLoaded,
        [GA_METRICS.TIME_TO_FIRST_BTYE]: timeToFirstByte,
      });
      this.trackEvent(CUSTOM_ACTION_TYPE.TRACK_TIMING, GA_CATEGORY.NAVIGATION_TIMING, 'ResponseEnd', responseEnd);
      this.trackEvent(CUSTOM_ACTION_TYPE.TRACK_TIMING, GA_CATEGORY.NAVIGATION_TIMING, 'DomLoaded', domLoaded);
      this.trackEvent(CUSTOM_ACTION_TYPE.TRACK_TIMING, GA_CATEGORY.NAVIGATION_TIMING, 'WindowLoaded', windowLoaded);
      this.trackEvent(CUSTOM_ACTION_TYPE.TRACK_TIMING, GA_CATEGORY.NAVIGATION_TIMING, 'TimeToFirstByte', timeToFirstByte);
    }
  }

}
