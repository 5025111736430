// CAN NOT BE CONST ENUM BECAUSE WE NEED TO EXPOSE TO THE TEMPLATE
export enum OVERLAYS {
  PART_LIST,
  NO_DOCKING,
  SAVE_DRAFT,
  VARIANT_WARNING,
  OPEN_AR,
  EXPORT_3D,
  ELEMENT_DISABLED,
  ADD_TO_CART,
  NOTICE,
  HELP,
  OLD_CONFIG,
}

export interface OverlayState {
  open: boolean;
  payload?: any;
}

export type OverlaysOpenState = {[key in OVERLAYS]: OverlayState};

export const initialOverlaysState = {
  [OVERLAYS.PART_LIST]: {open: false},
  [OVERLAYS.NO_DOCKING]: {open: false},
  [OVERLAYS.SAVE_DRAFT]: {open: false},
  [OVERLAYS.VARIANT_WARNING]: {open: false},
  [OVERLAYS.OPEN_AR]: {open: false},
  [OVERLAYS.EXPORT_3D]: {open: false},
  [OVERLAYS.ELEMENT_DISABLED]: {open: false},
  [OVERLAYS.ADD_TO_CART]: {open: false},
  [OVERLAYS.NOTICE]: {open: false},
  [OVERLAYS.OLD_CONFIG]: {open: false},
  [OVERLAYS.HELP]: {open: false},
};
