import Vue from 'vue';
import Vuex, {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {StoreState} from '@/configurator/store/index';
import {Nullable} from '@/common/utils/types';
import {CORE_DATA_MUTATIONS, ParameterGroup} from '@/configurator/store/core-data';
import {CollectionViewElement} from '@/configurator/components/collection-view/-utils/types';
import {KernelComponent, UiPossibleChild} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {addonsHaveGroups, filterAndMapAddons, mapAddons, mapVariants} from '@/common/utils/parameters';
import {initialOverlaysState, OVERLAYS, OverlaysOpenState} from '@/configurator/components/-utils/overlays';
import {UiInitData} from '@/configurator/embedding/types';
import {RapiTagForUi, RapiId} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';

import {API_BASE} from '@/common/utils/api-url';

Vue.use(Vuex);

const namespaced: boolean = false;

export enum BUTTON_ACTIONS {
  AR = 0,
  PARTLIST = 1,
  MULTISELECT = 2,
  DIMENSIONS = 3,
  FULLSCREEN = 4,
  RESET_CAMERA = 5,
  RENDER_IMAGE = 6,
}

export interface SceneSelection {
  selectionMode: string;
  hasChildren: boolean;
  isRoot: boolean;
  components: KernelComponent[];
}

export interface UiState {
  selectedCollectionViewElement: Nullable<CollectionViewElement>;
  collectionViewShowsDetails: boolean;
  selectedGroup: Nullable<ParameterGroup>;
  interactionsExpanded: boolean;
  gridViewSearch: Nullable<string>;
  showAllAsList: boolean;
  gridViewFilter: Nullable<string>;
  isDesktop: boolean;
  wasDesktopBefore: boolean;
  isLandscape: boolean;
  overlays: OverlaysOpenState;
  selectedActions: BUTTON_ACTIONS[];
  lastRegularViewMode: Nullable<boolean>;
  isFullscreen: boolean;
  onlyShowAddons: boolean;
  activeAddon: Nullable<UiPossibleChild>;
  currentSelection: Nullable<SceneSelection>;
  initData: Nullable<UiInitData>;
  isInIframe: boolean;
  isViewOnly: boolean;
  isExpandedForConfigure: boolean;
  isLoadingInProgress: boolean;
  currentVariants: Nullable<RapiTagForUi>;
  lastRequestedId: Nullable<RapiId>;
  showVariants: boolean;
  isViewer: boolean;
  isConfigurator: boolean;
  email: Nullable<string>;
  userInitiatedSearch: boolean;
}

export enum UI_STATE_MUTATIONS {
  SET_SELECTED_COLLECTION_VIEW_ELEMENT = 'setSelectedCollectionViewElement',
  SET_SELECTED_GROUP = 'setSelectedGroup',
  SET_GRID_VIEW_SEARCH = 'setgridViewSearch',
  SET_USER_INITIATED_SEARCH = 'setUserInitiatedSearch',
  TOGGLE_LIST_DISPLAY_TYPE = 'toggleListDisplayType',
  SET_GRID_VIEW_FILTER = 'setgridViewFilter',
  SET_IS_DESKTOP = 'setIsDesktop',
  SET_ACTION_SELECTED = 'setActionSelected',
  SET_ACTION_DESELECTED = 'setActionDeselected',
  SET_INTERACTIONS_EXPANDED = 'setInteractionsExpanded',
  SET_IS_LANDSCAPE = 'setIsLandscape',
  SET_IS_PART_LIST_SHOWN = 'SET_IS_PART_LIST_SHOWN',
  SET_LAST_REGULAR_VIEW_MODE = 'SET_LAST_REGULAR_VIEW_MODE',
  SET_IS_FULLSCREEN = 'setIsFullscreen',
  COLLECTION_VIEW_ELEMENT_EXPANDED = 'COLLECTION_VIEW_ELEMENT_EXPANDED',
  SHOW_ONLY_ADDONS = 'SHOW_ONLY_ADDONS',
  SHOW_ALL_COLLECTION_VIEW_ELEMENTS = 'SHOW_ALL_COLLECTION_VIEW_ELEMENTS',
  SET_ACTIVE_ADDON = 'SET_ACTIVE_ADDON',
  SET_CURRENT_SELECTION = 'SET_CURRENT_SELECTION',
  SET_OVERLAY_STATE = 'SET_OVERLAY_STATE',
  CLOSE_ALL_OVERLAYS = 'CLOSE_ALL_OVERLAYS',
  SET_INIT_DATA = 'SET_INIT_DATA',
  SET_IS_IN_IFRAME = 'SET_IS_IN_IFRAME',
  SET_IS_VIEW_ONLY = 'SET_IS_VIEW_ONLY',
  SET_IS_EXPANDED_FOR_CONFIGURE = 'SET_IS_EXPANDED_FOR_CONFIGURE',
  SET_IS_LOADING_IN_PROGRESS = 'SET_IS_LOADING_IN_PROGRESS',
  SET_CURRENT_VARIANTS = 'SET_CURRENT_VARIANTS',
  SET_LAST_REQUESTED_ID = 'SET_LAST_REQUESTED_ID',
  SET_SHOW_VARIANTS = 'SET_SHOW_VARIANTS',
  SET_EMAIL = 'SET_EMAIL',
}

enum UI_STATE_INTERAL_MUTATIONS {
  SET_IS_VIEWER = 'SET_IS_VIEWER',
  SET_IS_CONFIGURATOR = 'SET_IS_CONFIGURATOR',
}

export enum UI_STATE_ACTIONS {
  SELECT_GROUP = 'SELECT_GROUP',
  SELECT_VIEW_MODE = 'SELECT_VIEW_MODE',
  SET_INTERACTIONS_EXPANDED = 'SET_INTERACTIONS_EXPANDED',
  SELECTION_CHANGE = 'SELECTION_CHANGE',
  RESET_COLLECTION_VIEW = 'RESET_COLLECTION_VIEW',
  START_CONFIGURING = 'START_CONFIGURING',
  PAUSE_CONFIGURING = 'PAUSE_CONFIGURING',
  USE_VIEWER = 'USE_VIEWER',
  USE_CONFIGURATOR = 'USE_CONFIGURATOR',
}

export enum UI_STATE_GETTERS {
  USE_REGULAR_LAYOUT = 'USE_REGULAR_LAYOUT',
  COLLECTION_VIEW_ELEMENTS = 'COLLECTION_VIEW_ELEMENTS',
  COLLECTION_VIEW_ADDON_ELEMENTS = 'COLLECTION_VIEW_ADDON_ELEMENTS',
  COLLECTION_VIEW_VARIANTS = 'COLLECTION_VIEW_VARIANTS',
  SHOW_BACK_TO_WEBSITE = 'SHOW_BACK_TO_WEBSITE',
}

export const initialState: UiState = {
  selectedCollectionViewElement: null,
  collectionViewShowsDetails: false,
  selectedGroup: null,
  interactionsExpanded: false,
  gridViewSearch: null,
  showAllAsList: false,
  gridViewFilter: null,
  isDesktop: false,
  wasDesktopBefore: false,
  isLandscape: false,
  overlays: initialOverlaysState,
  lastRegularViewMode: null,
  selectedActions: [],
  isFullscreen: false,
  onlyShowAddons: false,
  activeAddon: null,
  currentSelection: null,
  initData: {
    configuratorId: 'vallone',
  },
  isInIframe: false,
  isViewOnly: false,
  isExpandedForConfigure: false,
  isLoadingInProgress: true,
  currentVariants: null,
  lastRequestedId: null,
  showVariants: false,
  isConfigurator: false,
  isViewer: false,
  email: null,
  userInitiatedSearch: false,
};

const gettersImplementation: GetterTree<UiState, StoreState> = {
  [UI_STATE_GETTERS.USE_REGULAR_LAYOUT](state) {
    return (state.isDesktop || !state.isLandscape || !!state.initData?.mobileLandscape);
  },
  [UI_STATE_GETTERS.COLLECTION_VIEW_ELEMENTS](_state, _getters, {coreData}) {
    const {parameters, addons} = coreData;
    if (!parameters.length && !addons.length) {
      return [];
    }
    const parameterElements = parameters.map((collectionViewElement) => ({
      label: collectionViewElement.label,
      key: 'parameter_' + collectionViewElement.key,
      payload: collectionViewElement,
      type: 'parameter',
    }));
    if (!addonsHaveGroups(addons)) {
      return [...parameterElements];
    }
    return [
      ...parameterElements,
      ...filterAndMapAddons(addons, _state.selectedGroup?.key),
    ];
  },
  [UI_STATE_GETTERS.COLLECTION_VIEW_ADDON_ELEMENTS](_state, _getters, {coreData}) {
    const {addons} = coreData;
    if (!addons.length) {
      return [];
    }
    return [
      ...mapAddons(addons),
    ];
  },
  [UI_STATE_GETTERS.SHOW_BACK_TO_WEBSITE](state) {
    const {isInIframe, isFullscreen, isViewOnly, isExpandedForConfigure} = state;
    return isInIframe && !isFullscreen && !isViewOnly && isExpandedForConfigure;
  },
  [UI_STATE_GETTERS.COLLECTION_VIEW_VARIANTS](state) {
    const currentVariants = state.currentVariants;
    return (currentVariants) ? [
      ...mapVariants(currentVariants),
    ] : [];
  },
};

const actions: ActionTree<UiState, StoreState> = {
  [UI_STATE_ACTIONS.SELECT_GROUP]({commit, dispatch}, parameterGroup: ParameterGroup): void {
    commit(UI_STATE_MUTATIONS.SET_SELECTED_GROUP, parameterGroup);
    if (!!parameterGroup) {
      commit(CORE_DATA_MUTATIONS.UPDATE_PARAMETERS, parameterGroup.parameters);
    }
    dispatch(UI_STATE_ACTIONS.RESET_COLLECTION_VIEW);
  },
  [UI_STATE_ACTIONS.SELECT_VIEW_MODE]({commit, state, getters, dispatch}, isDesktop: boolean): void {
    // PLEASE REFACTOR ME!! THIS IS SO CONFUSING...

    const isInitialSet = state.isDesktop === null;
    const wasSwitchFromViewMode = state.lastRegularViewMode !== isDesktop;

    commit(UI_STATE_MUTATIONS.SET_IS_DESKTOP, isDesktop);
    if (isInitialSet && state.isInIframe && !isDesktop) {
      commit(UI_STATE_MUTATIONS.SET_IS_FULLSCREEN, true);
    }
    const isRegularLayout = getters[UI_STATE_GETTERS.USE_REGULAR_LAYOUT];
    if (isRegularLayout) {
      commit(UI_STATE_MUTATIONS.SET_LAST_REGULAR_VIEW_MODE, isDesktop);
    }

    if (isInitialSet || (wasSwitchFromViewMode && isRegularLayout)) {
      if (!state.isDesktop && state.collectionViewShowsDetails) {
        commit(UI_STATE_MUTATIONS.SET_INTERACTIONS_EXPANDED, true);
      } else {
        commit(UI_STATE_MUTATIONS.SET_INTERACTIONS_EXPANDED, state.isDesktop);
      }
      if (!state.isDesktop && !state.interactionsExpanded) {
        // do something
      }
    }

    if (wasSwitchFromViewMode && state.isInIframe) {
      if (!state.isDesktop && !state.isExpandedForConfigure && !state.initData?.mobileLandscape) {
        dispatch(UI_STATE_ACTIONS.PAUSE_CONFIGURING);
      } else {
        dispatch(UI_STATE_ACTIONS.START_CONFIGURING);
      }
    }
  },
  [UI_STATE_ACTIONS.SET_INTERACTIONS_EXPANDED]({commit, state}, isExpanded: boolean): void {
    commit(UI_STATE_MUTATIONS.SET_INTERACTIONS_EXPANDED, isExpanded);
    if (!state.interactionsExpanded && !state.isDesktop) {
      commit(UI_STATE_MUTATIONS.COLLECTION_VIEW_ELEMENT_EXPANDED, false);
    }
  },

  [UI_STATE_ACTIONS.SELECTION_CHANGE]({dispatch}): void {
    dispatch(UI_STATE_ACTIONS.RESET_COLLECTION_VIEW);
  },

  [UI_STATE_ACTIONS.RESET_COLLECTION_VIEW]({commit, state}): void {
    commit(UI_STATE_MUTATIONS.SET_SELECTED_COLLECTION_VIEW_ELEMENT, null);
    commit(UI_STATE_MUTATIONS.SHOW_ALL_COLLECTION_VIEW_ELEMENTS);
    commit(UI_STATE_MUTATIONS.COLLECTION_VIEW_ELEMENT_EXPANDED, false);
    commit(UI_STATE_MUTATIONS.SET_SHOW_VARIANTS, false);
    if (!state.isDesktop) {
      commit(UI_STATE_MUTATIONS.SET_INTERACTIONS_EXPANDED, false);
    }
  },
  [UI_STATE_ACTIONS.START_CONFIGURING]({commit, state}): void {
    if (!state.isViewOnly) {
      return;
    }
    commit(UI_STATE_MUTATIONS.SET_IS_FULLSCREEN, false);
    commit(UI_STATE_MUTATIONS.SET_IS_VIEW_ONLY, false);
  },
  [UI_STATE_ACTIONS.PAUSE_CONFIGURING]({commit}): void {
    commit(UI_STATE_MUTATIONS.SET_IS_FULLSCREEN, true);
    commit(UI_STATE_MUTATIONS.SET_IS_VIEW_ONLY, true);
    commit(UI_STATE_MUTATIONS.SET_IS_EXPANDED_FOR_CONFIGURE, false);
  },
  [UI_STATE_ACTIONS.USE_CONFIGURATOR]({commit}): void {
    commit(UI_STATE_INTERAL_MUTATIONS.SET_IS_CONFIGURATOR, true);
    commit(UI_STATE_INTERAL_MUTATIONS.SET_IS_VIEWER, false);
  },
  [UI_STATE_ACTIONS.USE_VIEWER]({commit}): void {
    commit(UI_STATE_INTERAL_MUTATIONS.SET_IS_CONFIGURATOR, false);
    commit(UI_STATE_INTERAL_MUTATIONS.SET_IS_VIEWER, true);
  },
};

const mutations: MutationTree<UiState> = {
  [UI_STATE_MUTATIONS.SET_SELECTED_COLLECTION_VIEW_ELEMENT](state: UiState, selectedCollectionViewElement: CollectionViewElement) {
    state.selectedCollectionViewElement = selectedCollectionViewElement;
  },
  [UI_STATE_MUTATIONS.SET_SELECTED_GROUP](state: UiState, selectedGroup: ParameterGroup) {
    state.selectedGroup = selectedGroup;
  },
  [UI_STATE_MUTATIONS.SET_GRID_VIEW_SEARCH](state: UiState, searchString: Nullable<string>) {
    searchString = (searchString === '' || typeof searchString !== 'string') ? null : searchString.toLowerCase();
    state.gridViewSearch = searchString;
  },
  [UI_STATE_MUTATIONS.TOGGLE_LIST_DISPLAY_TYPE](state: UiState) {
    state.showAllAsList = !state.showAllAsList;
  },
  [UI_STATE_MUTATIONS.SET_GRID_VIEW_FILTER](state: UiState, id: string) {
    state.gridViewFilter = id;
  },
  [UI_STATE_MUTATIONS.SET_IS_DESKTOP](state: UiState, isDesktop: boolean) {
    state.wasDesktopBefore = state.isDesktop === null ? false : state.isDesktop;
    state.isDesktop = isDesktop;
  },
  [UI_STATE_MUTATIONS.SET_ACTION_SELECTED](state: UiState, action: BUTTON_ACTIONS) {
    if (!state.selectedActions.includes(action)) {
      state.selectedActions.push(action);
    }
  },
  [UI_STATE_MUTATIONS.SET_ACTION_DESELECTED](state: UiState, action: BUTTON_ACTIONS) {
    if (state.selectedActions.includes(action)) {
      state.selectedActions.splice(state.selectedActions.indexOf(action), 1);
    }
  },
  [UI_STATE_MUTATIONS.SET_INTERACTIONS_EXPANDED](state: UiState, isExpanded: boolean) {
    state.interactionsExpanded = isExpanded;
  },
  [UI_STATE_MUTATIONS.SET_IS_LANDSCAPE](state: UiState, isLandscape: boolean) {
    state.isLandscape = isLandscape;
  },
  [UI_STATE_MUTATIONS.SET_OVERLAY_STATE](state: UiState, {overlay, open, payload = null}: {overlay: OVERLAYS, open: boolean, payload?: any}) {
    // NEED A NEW OBJECT DUE TO REACTIVITY CAVEAT and also because we do not want to alter initialOverlaysState
    state.overlays = {...initialOverlaysState};
    state.overlays[overlay] = {open, payload};
    if (open) {
      // @ts-ignore
      if (window.customConfig ? window.customConfig.general.look === 'default' : true) {
        window.parent.postMessage('just disable overflow', API_BASE);
      } else {
        window.parent.postMessage('disable overflow', API_BASE);
      }
    }
  },
  [UI_STATE_MUTATIONS.CLOSE_ALL_OVERLAYS](state: UiState) {
    state.overlays = {...initialOverlaysState};
  },
  [UI_STATE_MUTATIONS.SET_LAST_REGULAR_VIEW_MODE](state: UiState, isDesktop: boolean) {
    state.lastRegularViewMode = isDesktop;
  },
  [UI_STATE_MUTATIONS.SET_IS_FULLSCREEN](state: UiState, isFullscreen: boolean) {
    state.isFullscreen = isFullscreen;
  },
  [UI_STATE_MUTATIONS.COLLECTION_VIEW_ELEMENT_EXPANDED](state: UiState, doesCollectionViewShowsDetails: boolean) {
    state.collectionViewShowsDetails = doesCollectionViewShowsDetails;
  },
  [UI_STATE_MUTATIONS.SHOW_ONLY_ADDONS](state: UiState) {
    state.onlyShowAddons = true;
  },
  [UI_STATE_MUTATIONS.SHOW_ALL_COLLECTION_VIEW_ELEMENTS](state: UiState) {
    state.onlyShowAddons = false;
  },
  [UI_STATE_MUTATIONS.SET_ACTIVE_ADDON](state: UiState, possibleChild: UiPossibleChild) {
    state.activeAddon = possibleChild;
  },
  [UI_STATE_MUTATIONS.SET_CURRENT_SELECTION](state: UiState, selection: Nullable<SceneSelection>) {
    state.currentSelection = selection;
  },
  [UI_STATE_MUTATIONS.SET_INIT_DATA](state: UiState, initData: Nullable<UiInitData>) {
    state.initData = initData;
  },
  [UI_STATE_MUTATIONS.SET_IS_IN_IFRAME](state: UiState, isInIframe: boolean) {
    state.isInIframe = isInIframe;
  },
  [UI_STATE_MUTATIONS.SET_IS_VIEW_ONLY](state: UiState, isViewOnly: boolean) {
    state.isViewOnly = isViewOnly;
  },
  [UI_STATE_MUTATIONS.SET_IS_EXPANDED_FOR_CONFIGURE](state: UiState, isExpandedForConfigure: boolean) {
    state.isExpandedForConfigure = isExpandedForConfigure;
  },
  [UI_STATE_MUTATIONS.SET_IS_LOADING_IN_PROGRESS](state: UiState, isLoadingInProgress: boolean) {
    state.isLoadingInProgress = isLoadingInProgress;
  },
  [UI_STATE_MUTATIONS.SET_CURRENT_VARIANTS](state: UiState, variants: RapiTagForUi) {
    state.currentVariants = variants;
  },
  [UI_STATE_MUTATIONS.SET_LAST_REQUESTED_ID](state: UiState, id: RapiId) {
    state.lastRequestedId = id;
  },
  [UI_STATE_MUTATIONS.SET_SHOW_VARIANTS](state: UiState, isShown: boolean) {
    state.showVariants = isShown;
  },
  [UI_STATE_INTERAL_MUTATIONS.SET_IS_CONFIGURATOR](state: UiState, isConfigurator: boolean) {
    state.isConfigurator = isConfigurator;
  },
  [UI_STATE_INTERAL_MUTATIONS.SET_IS_VIEWER](state: UiState, isViewer: boolean) {
    state.isViewer = isViewer;
  },
  [UI_STATE_MUTATIONS.SET_EMAIL](state: UiState, email: string) {
    state.email = email;
  },
  [UI_STATE_MUTATIONS.SET_USER_INITIATED_SEARCH](state: UiState, isUserSearching: boolean) {
    state.userInitiatedSearch = isUserSearching;
  },
};

export const uiState: Module<UiState, StoreState> = {
  namespaced,
  state: initialState,
  getters: gettersImplementation,
  actions,
  mutations,
};
