export class Libs {
  public async getJsPdf() {
    const {jsPDF} = await import('jspdf');
    const {default: autoTable} = await import('jspdf-autotable');
    return {jsPDF, autoTable};
  }
}

const LibsPlugin = {
  install(Vue: any) {
    Vue.prototype.$libs = new Libs();
  },
};

export default LibsPlugin;
