import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
import {Store} from 'vuex';
import {MUTATIONS, StoreState} from '@/configurator/store';
import {BUTTON_ACTIONS, UI_STATE_ACTIONS, UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {Nullable} from '@/common/utils/types';
import {KernelComponent, KernelParameter, KernelParameterGroup, KernelPartList, UiKernelParameter, UiPossibleChildTag} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {CORE_DATA_ACTIONS, CORE_DATA_MUTATIONS, ParameterGroup} from '@/configurator/store/core-data';
import {checkForActivePackage, getHostname, isIdAnItem, KernelBoundsFormatted, NAMES_FOR_LOCALHOST} from '@/common/utils/helper';
import {CORE_STATE_MUTATIONS} from '@/configurator/store/core-state';
import {UiCallbacks} from '@/configurator/business-logic/ui-callback';
import ConfiguratorUiCallbacks from '@roomle/web-sdk/lib/definitions/configurator-core/src/services/configurator-ui-callback';
import {OVERLAYS} from '../components/-utils/overlays';
import {removeLoadingScreen, setLoadingProgress} from '@/common/utils/dom';
import {GA_CATEGORY, GA_METRICS} from '@/common/utils/google-analytics';
import {Analytics} from '@/common/plugins/analytics';
import {RapiId} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {getCurrentVariants} from '@/common/utils/parameters';
import {RoomleSdkWrapper} from '@/configurator/business-logic/roomle-sdk-wrapper';
import RoomleGLBViewer from '@roomle/web-sdk/lib/definitions/glb-viewer-core/src/roomle-glb-viewer';
import RapiAccess from '@roomle/web-sdk/lib/definitions/common-core/src/rapi-access';
import {addLoadMark, calcTiming} from '@/common/utils/performance';
// tslint:disable:no-unused-expression -- only import type because otherwise everything is bundled
import type {MessageHandler} from '@/configurator/embedding/message-handler';
import type {ExposedCallbacks} from '@/configurator/embedding/exposed-callbacks';
import {ExposedApi} from '@/configurator/embedding/exposed-api';
import {ConfiguratorSettings, UiInitData} from '@/configurator/embedding/types';
import {getConfiguratorSettings} from '@/configurator/embedding/utils';
import {sameOrigin} from '@/common/utils/iframe';

import {getRequestData} from '@/common/utils/get-request-data';
import {API_BASE} from '@/common/utils/api-url';
// tslint:enable:no-unused-expression

// can only import type of ConfiguratorUiCallbacks but can not use it for new. This is because we bundle our stuff into random chunks and
// therefore TS compiler can not find it
// import ConfiguratorUiCallbacks from '@roomle/web-sdk/lib/definitions/configurator-core/src/services/configurator-ui-callback';

export enum SDK_MODULES {
  VIEWER = 0,
  CONFIGURATOR = 1,
}

export interface Selectable {
  key: string;
}

export interface UiPossibleChildTagWithKey extends UiPossibleChildTag {
  key: string;
}

export interface LoadResponse {
  partList: KernelPartList;
}

export const getSelectableEntry = (selected: Selectable, data: Selectable[]): Nullable<Selectable> => {
  const validData = data && data.length;
  if (!validData) {
    return null;
  }
  return (!selected) ? null : (data.find((entry) => selected.key === entry.key) || null);

};

export const REGISTERED_CALLBACKS: Map<keyof ConfiguratorUiCallbacks, boolean> = new Map();

export class SdkConnector {

  protected loadInProgress: boolean = false; // protected so we can override it for tests
  protected someLoadDone: boolean = false; // protected so we can override it for tests

  private _configurator: Nullable<RoomleConfigurator> = null;
  private _store: Store<StoreState>;
  private _initWaiters: Array<(configurator: RoomleConfigurator) => void> = [];
  private _loadWaiters: Array<(kernelParts: Nullable<LoadResponse>) => void> = [];
  private _uiCallbacks: UiCallbacks[] = [];
  private _analytics: Nullable<Analytics>;
  private _sdkWrapper: RoomleSdkWrapper;
  private _initDone: boolean = false;
  private _glbViewer: Nullable<RoomleGLBViewer> = null;
  private _messageHandler: Nullable<MessageHandler> = null;
  private _embeddingCallbacks: Nullable<ExposedCallbacks>;

  private _initDoneViewer: boolean = false;
  private _initWaitersViewer: Array<(configurator: RoomleGLBViewer) => void> = [];
  private _waitForCanvasElementCallback: Nullable<(element: HTMLElement) => void> = null;

  private _configuratorSettingsCache: Map<string, ConfiguratorSettings> = new Map();

  get api(): Promise<RoomleConfigurator> {
    if (this._configurator) {
      return Promise.resolve(this._configurator);
    }
    return new Promise((resolve) => this._initWaiters.push(resolve));
  }

  get glbViewerApi(): Promise<RoomleGLBViewer> {
    if (this._glbViewer) {
      return Promise.resolve(this._glbViewer);
    }
    return new Promise((resolve) => this._initWaitersViewer.push(resolve));
  }

  constructor(sdkWrapper: RoomleSdkWrapper, store: Store<StoreState>, analytics: Nullable<Analytics> = null, embeddingCallbacks: Nullable<ExposedCallbacks> = null) {
    this._analytics = analytics;
    this._sdkWrapper = sdkWrapper;
    this._store = store;
    this._embeddingCallbacks = embeddingCallbacks;
  }

  public setMessageHandler(messageHandler: MessageHandler) {
    this._messageHandler = messageHandler;
  }

  public async createExposedApi(isViewer: boolean) {
    // if message handler is not defined (needed for embedding api)
    // and iframe and parent are different origin -> something is wrong
    if (!this._messageHandler && !sameOrigin()) {
      console.error('Message handler not defined? Maybe you called createExposedApi too early?');
      return;
    }

    const api = (isViewer) ? await this.glbViewerApi : await this.api;
    // tslint:disable-next-line:no-unused-expression
    new ExposedApi(
      this,
      this._messageHandler,
      api,
      this._embeddingCallbacks!,
      this._store,
      this._analytics!,
    );
  }

  public async getRapiAccess(): Promise<RapiAccess> {
    return await this._sdkWrapper.getRapiAccess();
  }

  public async loadConfiguration(configurationId: string, initData?: UiInitData | undefined): Promise<Nullable<LoadResponse>> {
    this._fetchVariants(configurationId);
    return await this._execLoadingConfiguratorProcess(configurationId, async (id: string) => (await this.api).loadConfigurationById(id, initData));
  }

  public async loadConfigurationString(configurationString: string, initData?: UiInitData | undefined): Promise<Nullable<LoadResponse>> {
    // check for variants here because parsing the configuration string can be very expensive
    if (this._store.state.uiState.initData?.variants) {
      try {
        if (!configurationString) {
          return null;
        }
        const {componentId} = JSON.parse(configurationString);
        this._fetchVariants(componentId);
      } catch (e) {
        console.error('Problems fetching variants for configuration string');
      }
    }
    return this._execLoadingConfiguratorProcess(configurationString, async (confString: string) => (await this.api).loadConfiguration(confString, initData));
  }

  public async loadConfigurableItem(configurableItemId: string, initData?: UiInitData | undefined): Promise<Nullable<LoadResponse>> {
    const result = this._execLoadingConfiguratorProcess(configurableItemId, async (itemId: string) => (await this.api).loadConfigurableItemById(itemId, initData));
    // since a configurableItemId does not need to match with the root component ID
    // we need to fetch the current configuration hash from the kernel, this is not
    // a network call but we need to wait until the kernel is ready
    this.waitForLoad().then(async () => {
      const api = await this.api;
      this._fetchVariants(await api.getCurrentConfigurationHash());
    });
    return result;
  }

  public waitForLoad(): Promise<Nullable<LoadResponse>> {
    if (!this.loadInProgress && this.someLoadDone) {
      return Promise.resolve(null);
    }
    return new Promise((resolve) => this._loadWaiters.push(resolve));
  }

  public initGlbViewer(glbViewer: RoomleGLBViewer) {
    if (this._initDoneViewer) {
      return;
    }
    this._glbViewer = glbViewer;
    console.warn('please implement this._initWaiters.forEach otherwise embedding does not work correctly');
    console.warn('the problem arises here: const api = await this.embeddingApi.create because without the initWaiter ');
    console.warn('the promise never resovles and therefor embedding does not work as intended');
    this._initWaitersViewer.forEach((callback) => callback(this._glbViewer!));
    this._initWaitersViewer = [];
    this._initDoneViewer = true;
  }

  public initConfigurator(configurator: RoomleConfigurator) {
    if (this._initDone) {
      return;
    }
    // need to clear the callbacks on init to make browser-syncs live reload
    // working in iframe as well
    REGISTERED_CALLBACKS.clear();
    this._configurator = configurator;

    this.addCallback('onConfigurationLabelChange', (catalogName: string, rapiItemLabel: string, rootComponentLabel: string) => {
      const safeLabel = rootComponentLabel || rapiItemLabel || catalogName || '';
      this._store.commit(MUTATIONS.SET_LABEL, safeLabel);
      if (!catalogName || safeLabel === catalogName) {
        return;
      }
      this._store.commit(CORE_STATE_MUTATIONS.SET_CATALOG_LABEL, catalogName);
    });

    this.addCallback('onUpdateParameters', async (parameters: UiKernelParameter[]) => {
      const stateParams: UiKernelParameter[] = [];
      this._store.state.coreData.groups.forEach((g) => g.parameters.forEach((p) => stateParams.push(p)));

      const changedParams: UiKernelParameter[] = [];
      const changedParamsKeys: string[] = [];

      stateParams.forEach((stateParam) => {
        const param = parameters.filter((parameter) => parameter.key === stateParam.key)?.[0];
        if (param && param.value !== stateParam.value) {
          changedParams.push(stateParam);
          changedParamsKeys.push(stateParam.key);
        }
      });

      // console.log(parameters);
      // console.log(stateParams);
      // console.log(changedParams);
      console.log(changedParamsKeys);

      // tslint:disable:max-line-length
      if (!changedParams.length || changedParams.length === 1 || this._store.state.coreData.undoRedoTrigger) {
        this._store.commit(CORE_DATA_MUTATIONS.UPDATE_UNDO_REDO_TRIGGER, false);
        // console.log('only one changed, nothing to do');
      } else {
        // console.log(changedParams.map((p) => `${p.label}: ${p.key}`).join('\n'));
        let payload = {};
        if (this._store.state.coreState.label.toLowerCase() === 'lax “integrated”') {
          if (changedParamsKeys.includes('handleType') && changedParamsKeys.includes('frontMaterial')) {
            payload = {
              paramHeadline: 'Finish Möbel',
              paramName: 'Farben / Lackiert',
              description: 'Du hast dich für den <b>Grifftyp "Griffmulde"</b> entschieden. Die <b>"Griffmulde"</b> kann ausschließlich mit farblich lackierten Oberflächen kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('length') && changedParamsKeys.includes('tapDeckPosition')) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Seitlich ist kein Platz für eine Armatur.',
            };
          } else if (changedParamsKeys.includes('basinDesignCaldera') && changedParamsKeys.includes('tapMounting')) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Seitliche Armatur nicht auswählbar, nur hinten / Wand möglich',
            };
          } else if (changedParamsKeys.includes('depth') && changedParamsKeys.includes('basinDesignCara')) {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Caldera',
              description: 'Das Waschbecken Caldera ist nur bei einer Tiefe von <b>"46 cm"</b> auswählbar',
            };
          } else if (changedParamsKeys.includes('depth') && changedParamsKeys.includes('tapMounting')) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Tiefe von <b>"46 cm"</b> entschieden. Eine Aufsatzarmatur <b>"hinten"</b> kann ausschließlich mit einer Tiefe von <b>"51 cm"</b> kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('look') && changedParamsKeys.includes('basinDesignCara')) {
            payload = {
              paramHeadline: 'Look',
              paramName: 'slim / bold',
              description: 'Du hast dich für eine <b>"slim"</b> Look entschieden. Unser Aufsatzbecken Caldera kann ausschließlich als <b>"bold"</b> Look kombiniert werden.',
            };
          }
        } else if (this._store.state.coreState.label.toLowerCase() === 'lax "bowl on top"') {
          if (changedParamsKeys.includes('depth') && changedParamsKeys.includes('basinDesign')) {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Typ',
              description: `Du hast dich für eine Tiefe von <b>"46 cm"</b> entschieden. Unser Waschbecken <b>"${changedParams.filter((param: any) => param.key === 'basinDesign')[0]?.valueLabel}"</b> kann ausschließlich mit einer Tiefe von <b>"51 cm"</b> kombiniert werden.`,
            };
          } else if (changedParamsKeys.includes('length') && changedParamsKeys.includes('basinDesign')) {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Typ',
              description: `Du hast dich für eine Länge von <b>"60 cm"</b> entschieden. Unser Waschbecken <b>"${changedParams.filter((param: any) => param.key === 'basinDesign')[0]?.valueLabel}"</b> kann erst ab einer Länge von <b>"70 cm"</b> kombiniert werden.`,
            };
          } else if (changedParamsKeys.includes('handleType') && changedParamsKeys.includes('frontMaterial')) {
            payload = {
              paramHeadline: 'Finish Möbel',
              paramName: 'Farben / Lackiert',
              description: 'Du hast dich für den Grifftyp <b>"Griffmulde"</b> entschieden. Die <b>"Griffmulde"</b> kann ausschließlich mit farblich lackierten Oberflächen kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('length') && changedParamsKeys.includes('tapMounting')) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: `Du hast dich für eine Länge von <b>"${parameters.filter((param: any) => param.key === 'comp')[0]?.valueLabel.replace('0 mm', '')} cm"</b> entschieden. Unsere Aufsatzarmaturen können erst ab einer Länge von <b>"80 cm"</b> seitlich positioniert werden.`,
            };
          }
        } else if (this._store.state.coreState.label.toLowerCase() === 'lax "floating duo"') {
          if (changedParamsKeys.includes('depth') && changedParamsKeys.includes('tapDeckPosition')) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Tiefe von <b>"46 cm"</b> entschieden. Eine Aufsatzarmatur <b>"hinten"</b> kann ausschließlich mit einer Tiefe von <b>"51 cm"</b> kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('boardWidth') && (changedParamsKeys.includes('tapDeckPosition') || changedParamsKeys.includes('tapMounting'))) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: `Du hast dich für eine Länge von <b>"${parameters.filter((param: any) => param.key === 'boardWidth')[0]?.valueLabel.replace('0 mm', '')} cm"</b> entschieden. Unser Aufsatzarmaturen können erst ab einer Länge von <b>"84 cm"</b> seitlich positioniert werden.`,
            };
          } else if (changedParamsKeys.includes('depth') && changedParamsKeys.includes('basinDesignCara')) {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Caldera',
              description: 'Das Waschbecken CALDERA ist nur bei einer Tiefe von <b>"46 cm"</b> auswählbar',
            };
          } else if (changedParamsKeys.includes('handleType') && changedParamsKeys.includes('frontMaterial')) {
            payload = {
              paramHeadline: 'Finish Möbel',
              paramName: 'Farben / Lackiert',
              description: 'Du hast dich für den Grifftyp <b>"Griffmulde"</b> entschieden. Die <b>"Griffmulde"</b> kann ausschließlich mit farblich lackierten Oberflächen kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('depth') && changedParamsKeys.includes('basinDesignTop')) {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Typ',
              description: `Du hast dich für eine Tiefe von <b>"46 cm"</b> entschieden. Unser Waschbecken <b>"${changedParams.filter((param: any) => param.key === 'basinDesignTop')[0]?.valueLabel}"</b> kann ausschließlich mit einer Tiefe von <b>"51 cm"</b> kombiniert werden.`,
            };
          } else if ((changedParamsKeys.includes('setWidth') || changedParamsKeys.includes('wallL1') || changedParamsKeys.includes('wallL2')) && changedParamsKeys.includes('tapMounting')) {
            let widthSuggestion = '';
            parameters.forEach((param: any) => {
              if (param.key === 'basinDesignMurale' && !param.value.toLowerCase().includes('empty')) {
                widthSuggestion = '>95 mm';
              } else if (param.key === 'basinDesignCara' && !param.value.toLowerCase().includes('empty')) {
                widthSuggestion = '>110 mm';
              } else if ((param.key === 'basinDesignLitho' || param.key === 'basinDesignNano') && !param.value.toLowerCase().includes('empty')) {
                widthSuggestion = '>120 mm';
              } else {
                widthSuggestion = '>120 mm';
              }
            });
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: `Der Abstand <b>"L1/L2"</b> wurde verringert. Für die Platzierung einer <b>"seitlichen"</b> Armatur wird ein Abstand von <b>"${widthSuggestion}"</b> empfohlen.`,
            };
          }
        } else if (this._store.state.coreState.label.toLowerCase() === 'lax “meets floor”') {
          if (changedParamsKeys.includes('') && changedParamsKeys.includes('')) {
            payload = {
              paramHeadline: '',
              paramName: '',
              description: '',
            };
          } else if ((changedParamsKeys.includes('setWidth') || changedParamsKeys.includes('wallL1') || changedParamsKeys.includes('wallL2')) && changedParamsKeys.includes('tapMounting')) {
            let widthSuggestion = '';
            parameters.forEach((param: any) => {
              if (param.key === 'basinDesignMurale' && !param.value.toLowerCase().includes('empty')) {
                widthSuggestion = '>95 mm';
              } else if (param.key === 'basinDesignCara' && !param.value.toLowerCase().includes('empty')) {
                widthSuggestion = '>110 mm';
              } else if ((param.key === 'basinDesignLitho' || param.key === 'basinDesignNano') && !param.value.toLowerCase().includes('empty')) {
                widthSuggestion = '>120 mm';
              } else {
                widthSuggestion = '>120 mm';
              }
            });
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: `Der Abstand <b>"L1/L2"</b> wurde verringert. Für die Platzierung einer <b>"seitlichen"</b> Armatur wird ein Abstand von <b>"${widthSuggestion}"</b> empfohlen.`,
            };
          }
        } else if (this._store.state.coreState.label.toLowerCase() === 'tremezzo') {
          if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('basinTypeTop') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 63,4 left' && changedParams.filter((param: any) => param.key === 'basinTypeTop')[0]?.valueLabel !== 'BISCAYNE XL') {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Typ',
              description: `Du hast dich für eine Länge von <b>"63,4 cm"</b> entschieden. Unser Waschbecken <b>"${changedParams.filter((param: any) => param.key === 'basinTypeTop')[0]?.valueLabel}"</b> kann erst ab einer Länge von <b>"73,4 cm"</b> kombiniert werden.`,
            };
          } else if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('basinTypeTop') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 63,4 left' && changedParams.filter((param: any) => param.key === 'basinTypeTop')[0]?.valueLabel === 'BISCAYNE XL') {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Typ',
              description: 'Du hast dich für eine Länge von <b>"63,4 cm"</b> entschieden. Unser Waschbecken <b>"BISCAYNE XL"</b> kann erst ab einer Länge von <b>"83,4 cm"</b> kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('basinTypeTop') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 73,4 left' && changedParams.filter((param: any) => param.key === 'basinTypeTop')[0]?.valueLabel === 'BISCAYNE XL') {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Typ',
              description: 'Du hast dich für eine Länge von <b>"73,4 cm"</b> entschieden. Unser Waschbecken <b>"BISCAYNE XL"</b> kann erst ab einer Länge von <b>"83,4 cm"</b> kombiniert werden.',
            };
          } else if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('tapDeckPosition') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 63,4 left') {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Länge von <b>"63,4 cm"</b> entschieden. Unsere Aufsatzarmaturen können erst ab einer Länge von <b>"83,4 cm"</b> seitlich positioniert werden.',
            };
          } else if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('tapDeckPosition') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 73,4 left') {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Länge von <b>"73,4 cm"</b> entschieden. Unsere Aufsatzarmaturen können erst ab einer Länge von <b>"83,4 cm"</b> seitlich positioniert werden.',
            };
          } else if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('tapMounting') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 63,4 left') {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Länge von <b>"63,4 cm"</b> entschieden. Unsere Aufsatzarmaturen können erst ab einer Länge von <b>"73,4 cm"</b> seitlich positioniert werden.',
            };
          } else if (changedParamsKeys.includes('comp') && changedParamsKeys.includes('tapMounting') && parameters.filter((param: any) => param.key === 'comp')[0]?.value === '1_L. 73,4 left') {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Länge von <b>"73,4 cm"</b> entschieden. Unsere Aufsatzarmaturen können erst ab einer Länge von <b>"80 cm"</b> seitlich positioniert werden.',
            };
          }
        } else if (this._store.state.coreState.label.toLowerCase() === 'one up') {
          if (changedParamsKeys.includes('basinPlacement') && changedParamsKeys.includes('width') && parameters.filter((param: any) => param.key === 'basinPlacement')[0]?.value === 'SX') {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Länge',
              description: 'Du hast dich für die Becken-Platzierung <b>"links"</b> entschieden. Diese Konfiguration ist erst ab einer Länge von <b>"105 cm"</b> erhältlich.',
            };
          } else if (changedParamsKeys.includes('basinPlacement') && changedParamsKeys.includes('width') && parameters.filter((param: any) => param.key === 'basinPlacement')[0]?.value === 'DX') {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Länge',
              description: 'Du hast dich für die Becken-Platzierung <b>"rechts"</b> entschieden. Diese Konfiguration ist erst ab einer Länge von <b>"105 cm"</b> erhältlich.',
            };
          } else if (changedParamsKeys.includes('basinPlacement') && changedParamsKeys.includes('width') && parameters.filter((param: any) => param.key === 'basinPlacement')[0]?.value === '2P') {
            payload = {
              paramHeadline: 'Becken',
              paramName: 'Länge',
              description: 'Du hast dich für ein <b>"Doppelbecken"</b> entschieden. Diese Konfiguration ist erst ab einer Länge von <b>"140 cm"</b> erhältlich.',
            };
          } else if (changedParamsKeys.includes('width') && changedParamsKeys.includes('tapDeckPosition')) {
            payload = {
              paramHeadline: 'Armatur',
              paramName: 'Platzierung Armatur',
              description: 'Du hast dich für eine Länge von <b>"70 cm"</b> entschieden. Unser Aufsatzarmaturen können erst ab einer Länge von <b>"90 cm"</b> seitlich positioniert werden.',
            };
          }
        }

        if (Object.entries(payload).length) {
          this._store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.NOTICE, open: true, payload});
        }
      }
      // tslint:enable:max-line-length

      await this._updateParameterGroups(parameters);
    });

    this.addCallback('onUpdatePossibleChildren', async (possibleChildren: UiPossibleChildTag[]) => {
      await this._updateAddons(possibleChildren.map((possibleChild): UiPossibleChildTagWithKey => {
        possibleChild.key = possibleChild.id;
        return possibleChild as UiPossibleChildTagWithKey;
      }));
    });

    this.addCallback('onPartListUpdate', (partList: KernelPartList, _hash: string) => {
      if (!this._store.state.coreState.componentHasChildren && this._store.state.uiState.selectedActions.includes(BUTTON_ACTIONS.PARTLIST)) {
        this._store.commit(UI_STATE_MUTATIONS.SET_ACTION_SELECTED, BUTTON_ACTIONS.PARTLIST);
      }
      this._store.commit(CORE_DATA_MUTATIONS.UPDATE_PART_LIST, partList);

      this.getAndSetPrice();
    });

    this.addCallback('onConfigurationReady', (partList: KernelPartList, _hash: string, _label: string) => {
      if (partList) {
        this._store.commit(CORE_DATA_MUTATIONS.UPDATE_PART_LIST, partList);
      }
    });

    this.addCallback('onBoundsUpdate', (bounds: KernelBoundsFormatted) => this._store.commit(CORE_DATA_MUTATIONS.UPDATE_BOUNDS, bounds));

    this.addCallback('onConfigurationHasChildren', (hasChildren) => this._store.commit(CORE_STATE_MUTATIONS.SET_COMPONENT_HAS_CHILDREN, hasChildren));
    this.addCallback('onClickOutside', () => {
      this._configurator!.disableMultiselect();
      this._store.commit(UI_STATE_MUTATIONS.SET_ACTION_DESELECTED, BUTTON_ACTIONS.MULTISELECT);
    });
    this.addCallback('onDimensionsVisibilityChange', (visible) => {
      if (!visible) {
        this._store.commit(UI_STATE_MUTATIONS.SET_ACTION_DESELECTED, BUTTON_ACTIONS.DIMENSIONS);
      }
    });
    this.addCallback('onDockingsPreviewRemoved', () => this._store.commit(UI_STATE_MUTATIONS.SET_ACTIVE_ADDON, null));
    this.addCallback('onNoDockingsAvailable', () => {
      this._store.commit(UI_STATE_MUTATIONS.SET_ACTIVE_ADDON, null);
      this._store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.NO_DOCKING, open: true});
    });

    this.addCallback('onSelectionChange', (selectionMode: string, isRoot: boolean, hasChildren: boolean, components: KernelComponent[]) => {
      this._store.dispatch(UI_STATE_ACTIONS.SELECTION_CHANGE);
      if (!this._store.state.uiState.isDesktop) {
        this._store.dispatch(UI_STATE_ACTIONS.SET_INTERACTIONS_EXPANDED, false);
      }
      this._store.commit(UI_STATE_MUTATIONS.SET_CURRENT_SELECTION, {selectionMode, isRoot, hasChildren, components});
      this._uiCallbacks.forEach((callback) => callback.onSelectionChange(selectionMode, isRoot, hasChildren, components));
    });

    this.addCallback('onSelectionCancel', () => {
      this._store.commit(UI_STATE_MUTATIONS.SET_CURRENT_SELECTION, null);
      if (this._store.state.uiState.onlyShowAddons) {
        return;
      }
      this._store.dispatch(UI_STATE_ACTIONS.SELECTION_CHANGE);
      if (!this._store.state.uiState.isDesktop) {
        this._store.dispatch(UI_STATE_ACTIONS.SET_INTERACTIONS_EXPANDED, false);
      }
      this._uiCallbacks.forEach((callback) => callback.onSelectionCancel());
    });

    this.addCallback('onUpdatePrice', (currencySymbol: string, price: number) => {
      this.setPrice(currencySymbol, price);
    });

    this.addCallback('onUserInitiatedDockDone', (childDbId: RapiId, _childDockId: number, parentDbId: RapiId) => {
      this._analytics?.ga?.trackEvent('Dock', GA_CATEGORY.INTERACTION, childDbId + '#' + parentDbId);
    });

    this.addCallback('onKernelIsReady', () => {
      if (performance && performance.timing) {
        const time = Math.round((new Date()).getTime() - performance.timing.responseStart);
        this._analytics?.ga.trackTiming(GA_CATEGORY.TIMING, GA_METRICS.KERNEL_IS_READY, time, {
          [GA_METRICS.KERNEL_IS_READY]: time,
        });
      }
    });

    this.addCallback('onContentProblem', ({rapiPath, ids}) => {
      if (rapiPath && ids) {
        this._analytics?.ga.trackContentError('Trying to fetch "' + rapiPath + '" but the following IDs "' + ids + '" are not in the database, please check the script');
      } else {
        this._analytics?.ga.trackError('Reported content error but either ids ("' + ids + '") or rapiPath ("' + rapiPath + '") missing');
      }
    });
    this._initWaiters.forEach((callback) => callback(this._configurator!));
    this._initWaiters = [];
    this._initDone = true;
  }

  public async getAndSetPrice() {
    const requestData = await getRequestData(this, this._store, 'null', false);

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    };
    // @ts-ignore
    const response = await fetch(`${API_BASE}/api/v1/roomle/calc-price`, requestOptions);
    const json = await response.json();
    if (json.data.notFoundArticles.length) {
      console.warn('NOT FOUND ARTICLES:');
      console.table(json.data.notFoundArticles.map((article: any) => `${article.article?.title}: ${article.article?.articleNr}`));
    }
    this.setPrice(json.data.currency === 'EUR' ? '€' : (json.data.currency === 'CHF' ? 'CHF' : '$'), json.data.price);
    this.setArticles(json.data.articles);
    this.setAttributes(json.data.attributes);
    this.setTaxRate(json.data.taxRate);
    this.setIsShopAvailable(json.data.isShopAvailable);
  }

  public setPrice(currencySymbol: string, price: number) {
    this._store.dispatch(CORE_DATA_ACTIONS.UPDATE_PRICE, {currencySymbol, price});
  }

  public setTaxRate(taxRate: number) {
    this._store.dispatch(CORE_DATA_ACTIONS.UPDATE_TAX_RATE, taxRate);
  }

  public setIsShopAvailable(isShopAvailable: boolean) {
    this._store.dispatch(CORE_DATA_ACTIONS.UPDATE_IS_SHOP_AVAILABLE, isShopAvailable);
  }

  public setArticles(articles: any) {
    this._store.dispatch(CORE_DATA_ACTIONS.UPDATE_ARTICLES, {articles});
  }

  public setAttributes(attributes: any) {
    this._store.dispatch(CORE_DATA_ACTIONS.UPDATE_ATTRIBUTES, {attributes});
  }

  public addUiCallback(listener: UiCallbacks) {
    if (this._uiCallbacks.includes(listener)) {
      return;
    }
    this._uiCallbacks.push(listener);
  }

  public removeUiCallback(listener: UiCallbacks) {
    const index = this._uiCallbacks.indexOf(listener);
    if (index === -1) {
      return;
    }
    this._uiCallbacks.splice(index, 1);
  }

  public async setParameter(parameter: KernelParameter, value: string, isRaw: boolean = false): Promise<void> {
    const api = await this.api;
    if (this._analytics) {
      const selection = api.getCurrentSelection();
      const paramType = parameter.type ? parameter.type : '(UnknownType)';
      const paramKey = parameter.key ? parameter.key : '(UnknownKey)';
      this._analytics.ga?.trackEvent('Parameter:ChangeEvent', GA_CATEGORY.INTERACTION, selection.join(',') + '#' + paramType + '#' + paramKey + '#' + value);
    }
    return api.setParameter(parameter, value, isRaw);
  }

  public async hasActivePackage(): Promise<boolean> {
    const tenant = await this.getTenant();
    return checkForActivePackage(tenant);
  }

  public async isCorrectConfiguratorDomain(configuratorId: string): Promise<boolean> {
    const hostname = getHostname();
    if (hostname && NAMES_FOR_LOCALHOST.includes(hostname)) {
      return true;
    }
    const rapiAccess = await this.getRapiAccess();
    const configuratorObject = await rapiAccess.getConfiguratorSettings(configuratorId);
    const hasGlobalWildCard = (configuratorObject.allowedHosts as string[]).includes('*');
    if (hasGlobalWildCard) {
      return true;
    }

    if (!hostname) {
      console.error('document.referrer not available, check security settings! Otherwise we can not check configurator settings');
      return false;
    }

    if (!configuratorObject.allowedHosts.includes(hostname)) {
      console.error('None of the following domains "' + configuratorObject.allowedHosts.join(', ') + '" matched "' + hostname + '"');
      return false;
    }
    return true;
  }

  public async loadObject(id: string): Promise<Nullable<LoadResponse>> {
    if (this.loadInProgress) {
      console.warn('Will not load ' + id + ', please wait until the last object has finished loading!');
    }
    addLoadMark();
    this._startLoading();
    let result: Nullable<LoadResponse> = null;
    let alreadyLoadedAConfiguration = false;
    // handle configuration
    if (!isIdAnItem(id)) {
      result = await this.loadConfiguration(id);
      alreadyLoadedAConfiguration = true;
    }

    const isGlb = await this.isIdAGlb(id);

    // handle glb
    if (isGlb) {
      result = await this.loadStaticItem(id);
    } else if (!alreadyLoadedAConfiguration) {
      // handle configurable item
      result = await this.loadConfigurableItem(id);
    }
    calcTiming();
    return result;
  }

  public async loadStaticItem(id: Nullable<string>): Promise<null> {
    this._store.commit(UI_STATE_MUTATIONS.SET_LAST_REQUESTED_ID, id);
    this._startLoading();
    let api: RoomleGLBViewer;
    await this.initCanvasElement(SDK_MODULES.VIEWER);
    if (id) {
      api = await this.glbViewerApi;
      await api.loadStaticItem(id, setLoadingProgress);
    }
    removeLoadingScreen();
    return null;
  }

  public async isIdAGlb(id: string) {
    if (!isIdAnItem(id)) {
      return false;
    }
    const rapiAccess = await this.getRapiAccess();
    const item = await rapiAccess.getItem(id);
    return !item.configuration;
  }

  public canvasElementReady(element: HTMLElement) {
    if (this._waitForCanvasElementCallback) {
      this._waitForCanvasElementCallback(element);
    }
  }

  public async initCanvasElement(module: SDK_MODULES) {
    if (this._needsInit(module)) {
      const isViewer = module === SDK_MODULES.VIEWER;
      const {USE_VIEWER, USE_CONFIGURATOR} = UI_STATE_ACTIONS;
      // all those ternary operators should go away with: https://roomle.atlassian.net/browse/RML-44
      const moduleToActivate = (isViewer) ? USE_VIEWER : USE_CONFIGURATOR;
      const element = await this._waitForCanvasElement(moduleToActivate);
      const initData = this._store.state.uiState.initData!;
      const sdkMain = (isViewer) ? await this._sdkWrapper.getGlbViewer(element, initData) : await this._sdkWrapper.getConfigurator(element, initData);
      const api = sdkMain.getApi();
      await api.init(element);
      return (isViewer) ? this.initGlbViewer(api as RoomleGLBViewer) : this.initConfigurator(api as RoomleConfigurator);
    }
  }

  public async getTenant() {
    const rapiAccess = await this.getRapiAccess();
    const initData = this._store.state.uiState.initData!;
    const configuratorId = initData.configuratorId!;
    let configuratorSettings;
    if (this._configuratorSettingsCache.has(configuratorId)) {
      configuratorSettings = this._configuratorSettingsCache.get(configuratorId) as ConfiguratorSettings;
    } else {
      configuratorSettings = await getConfiguratorSettings(configuratorId, initData);
      this._configuratorSettingsCache.set(configuratorId, configuratorSettings);
    }
    return rapiAccess.getTenant(configuratorSettings.tenant);
  }

  public async saveCurrentConfiguration() {
    const api = await this.api;
    return api.saveCurrentConfiguration();
  }

  public giveGaConsent() {
    if (!this._analytics) {
      return;
    }
    this._analytics.ga.cleanUpHistory();
    this._analytics.ga.giveConsent();
  }

  // tslint:disable-next-line:member-ordering
  public addCallback<K extends keyof ConfiguratorUiCallbacks>(key: K, fun: ConfiguratorUiCallbacks[K], allowMultiple: boolean = false) {
    if (!allowMultiple && REGISTERED_CALLBACKS.has(key)) {
      throw new Error('Can not register callback "' + key + '" again!');
    }
    if (!this._configurator) {
      return console.warn('PLEASE IMPLEMENT ME, this is just a quick fix, for more details see the method "initGlbViewer"');
    }
    // see: SDK file packages/configurator-core/src/services/configurator-ui-callback.ts
    // those two functions are initilized with null on purpose
    const knownUndefined = ['onKernelIsReady', 'onComponentPositionsUpdated'];
    if (typeof this._configurator.callbacks[key] !== 'function' && !knownUndefined.includes(key)) {
      console.warn('Can only assign to function properties on callbacks object, maybe "' + key + '" is not defined on the object');
    }

    if (allowMultiple && (this._configurator.callbacks as any)[key]) {
      const oldCallback = (this._configurator.callbacks as any)[key].bind(this._configurator.callbacks);
      // tslint:disable-next-line:only-arrow-functions
      (this._configurator.callbacks as any)[key] = function () {
        (fun as any)(...arguments);
        oldCallback(...arguments);
      };
    } else {
      ((this._configurator.callbacks) as any)[key] = fun;
    }
    REGISTERED_CALLBACKS.set(key, true);
  }

  private async _updateParameterGroups(parameters: UiKernelParameter[]) {
    const groups: Map<string, ParameterGroup> = new Map<string, ParameterGroup>();
    parameters.forEach((parameter) => {
      if (parameter.grouping) {
        let group = null;
        if (groups.has(parameter.grouping.key)) {
          group = groups.get(parameter.grouping.key);
        } else {
          group = {
            key: parameter.grouping.key,
            collapsed: parameter.grouping.collapsed,
            label: parameter.grouping.label,
            sort: parameter.grouping.sort,
            parameters: [],
          };
          groups.set(group.key, group);
        }
        if (group) {
          group.parameters.push(parameter);
        }
      }
    });
    const api = await this.api;
    if (groups.size > 0) {
      const parameterGroups = Array.from(groups.values());
      parameterGroups.sort((g1, g2) => g1.sort - g2.sort);
      this._store.commit(MUTATIONS.UPDATE_GROUPS, parameterGroups);
      this._checkParameterGroups(api);

      const selectedGroup = (this._store.state as any).uiState.selectedGroup;
      let groupToSelect: Nullable<ParameterGroup> = null;
      if (selectedGroup) {
        groupToSelect = parameterGroups.find((param) => selectedGroup.key === param.key) || null;
      }
      if (!groupToSelect) {
        groupToSelect = parameterGroups[0];
      }
      // we always need to rerender parameters because from kernel we do not get a reference (JSON.parse(JSON.stringify))
      // and a parameter could be different and have the same key, e.g.: different valid values
      this._store.commit(UI_STATE_MUTATIONS.SET_SELECTED_GROUP, groupToSelect);
      this.api.then((configurator) => {
        const isGroupStillThere = this._store.state.coreData.groups.find((group) => groupToSelect?.key === group.key);
        if (!isGroupStillThere) {
          return;
        }
        configurator.setActiveGroupInView(groupToSelect ? groupToSelect.key : '');
      });
      this._updateParameters(groupToSelect!.parameters);
    } else {
      this._store.commit(MUTATIONS.UPDATE_GROUPS, []);
      this._updateParameters(parameters);
    }
  }

  private async _updateAddons(possibleChildrenTags: UiPossibleChildTagWithKey[]) {
    const api = await this.api;
    this._store.commit(CORE_DATA_MUTATIONS.UPDATE_ADDONS, possibleChildrenTags);
    this._checkParameterGroups(api);
  }

  private _checkParameterGroups(api: RoomleConfigurator): boolean {
    if (!!this._store.state.uiState.currentSelection) {
      return false;
    }
    const addonGroupKeys = new Set<string>();
    const storedAddons = this._store.state.coreData.addons;
    storedAddons.forEach((pct) => pct.possibleChildren.forEach((pc) => addonGroupKeys.add(pc.group)));
    let storedGroups = this._store.state.coreData.groups;
    const missingGroups = new Set<string>();

    // check if every group exists which is referenced in an addon
    addonGroupKeys.forEach((groupKey) => {
      const group = storedGroups.find((g) => g.key === groupKey);
      if (!group) {
        missingGroups.add(groupKey);
      }
    });

    // if a group is missing we check if it exists at all
    if (missingGroups.size) {
      storedGroups = this._store.state.coreData.groups;
      const allParameterGroups: KernelParameterGroup[] = api.getParameterGroups();

      // then we add missing groups to the UI
      missingGroups.forEach((groupKey) => {
        const missingGroup = allParameterGroups.find((g) => g.key === groupKey) as ParameterGroup;
        if (missingGroup && !storedGroups.find((g) => g.key === groupKey)) {
          missingGroup.parameters = [];
          storedGroups.push(missingGroup);
        }
      });
      storedGroups.sort((g1, g2) => g1.sort - g2.sort);
      this._store.commit(MUTATIONS.UPDATE_GROUPS, storedGroups);
      return true;
    } else {
      return false;
    }
  }

  private async _fetchVariants(id: Nullable<RapiId>): Promise<void> {
    this._store.commit(UI_STATE_MUTATIONS.SET_LAST_REQUESTED_ID, id);
    if (!id) {
      return;
    }
    const variantChangeMap = this._store.state.uiState.initData?.variants;
    if (!variantChangeMap) {
      return;
    }
    const api = await this.api;
    const tag = await getCurrentVariants(api, id, variantChangeMap);
    if (!tag) {
      return;
    }
    this._store.commit(UI_STATE_MUTATIONS.SET_CURRENT_VARIANTS, tag);
  }

  private _updateParameters(parameters: UiKernelParameter[]) {
    this._store.commit(MUTATIONS.UPDATE_PARAMETERS, parameters);
  }

  private _startLoading() {
    this.loadInProgress = true;
    this._store.commit(UI_STATE_MUTATIONS.SET_IS_LOADING_IN_PROGRESS, this.loadInProgress);
    this._store.commit(UI_STATE_MUTATIONS.SET_CURRENT_VARIANTS, null);
    this._store.dispatch(UI_STATE_ACTIONS.RESET_COLLECTION_VIEW, null);
  }

  private _finishLoading(kernelParts: Nullable<LoadResponse>) {
    removeLoadingScreen();
    this._loadWaiters.forEach((callback) => callback(kernelParts));
    this._loadWaiters = [];
    this.loadInProgress = false;
    this._store.commit(UI_STATE_MUTATIONS.SET_IS_LOADING_IN_PROGRESS, this.loadInProgress);
    this.someLoadDone = true;
    if (performance && performance.timing) {
      const time = Math.round((new Date()).getTime() - performance.timing.responseStart);
      this._analytics?.ga.trackTiming(GA_CATEGORY.TIMING, GA_METRICS.INIT_LOADING_SCREEN_CLOSE, time, {
        [GA_METRICS.INIT_LOADING_SCREEN_CLOSE]: time,
      });
    }
  }

  private async _execLoadingConfiguratorProcess(id: string, method: (id: string) => Promise<Nullable<LoadResponse>>) {
    this._startLoading();
    let result: Nullable<LoadResponse> = null;
    await this.initCanvasElement(SDK_MODULES.CONFIGURATOR);
    try {
      result = await method(id);
    } catch (e) {
      console.error(e);
      result = null;
    }
    this._finishLoading(result);
    /**
     * BREAKING SINCE VERSION 3.12.0-alpha.1 (as of 2020-11-05)
     * we return now the whole partlist object which we get from
     * the Core and not only the fullList
     * Link: https://gitlab.com/roomle/web/roomle-ui/-/merge_requests/303
     */
    if (this._store.state.uiState.initData?.featureFlags?.realPartList) {
      return result;
    } else {
      return (result) ? result.partList?.fullList as any : result;
    }
  }

  private _waitForCanvasElement(moduleToUse: UI_STATE_ACTIONS.USE_CONFIGURATOR | UI_STATE_ACTIONS.USE_VIEWER): Promise<HTMLElement> {
    return new Promise((resolve) => {
      this._store.dispatch(moduleToUse);
      this._waitForCanvasElementCallback = resolve;
    });
  }

  private _needsInit(module: SDK_MODULES) {
    const isCurrentlyConfigurator = this._store.state.uiState.isConfigurator;
    const isCurrentlyViewer = this._store.state.uiState.isViewer;
    const isInitialBoot = !isCurrentlyConfigurator && !isCurrentlyViewer;
    return isInitialBoot || (isCurrentlyConfigurator && module !== SDK_MODULES.CONFIGURATOR) || (isCurrentlyViewer && module !== SDK_MODULES.VIEWER);
  }
}
