import {SdkConnector} from '@/configurator/business-logic/sdk-connector';
import {Analytics} from '@/common/plugins/analytics';
import {RoomleSdkWrapper} from '@/configurator/business-logic/roomle-sdk-wrapper';
import {Store} from 'vuex';
import {StoreState} from '@/configurator/store';
import {ExposedCallbacks} from '@/configurator/embedding/exposed-callbacks';

const SdkConnectorPlugin = {
  install(Vue: any, {analytics, store, sdkWrapper, embeddingCallbacks}: {analytics: Analytics, sdkWrapper: RoomleSdkWrapper, store: Store<StoreState>, embeddingCallbacks: ExposedCallbacks}) {
    if (!analytics) {
      throw new Error('Sdk connector plugin needs analytics');
    }
    // tslint:disable-next-line
    Vue.prototype.$sdkConnector = new SdkConnector(sdkWrapper, store, analytics, embeddingCallbacks);
    // uncomment the next line if you want to debug $sdkConnector in devTools
    // (window as any).$sdkConnector = Vue.prototype.$sdkConnector;
  },
};

export default SdkConnectorPlugin;
