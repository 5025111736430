
export const getGeneralCustomConfig = () => {
  // @ts-ignore
  return window.customConfig ? window.customConfig.general : {};
};

export const getAttributesCustomConfig = () => {
  // @ts-ignore
  return window.customConfig ? window.customConfig.attributes : {};
};

export const getGroupCustomConfigByKey = (groupKey: string) => {
  // @ts-ignore
  if (window.customConfig && window.customConfig.groups) {
    // @ts-ignore
    return window.customConfig.groups[groupKey];
  }
  return {};
};

export const getFieldCustomConfigByKey = (fieldKey: string) => {
  // @ts-ignore
  if (window.customConfig && window.customConfig.fields) {
    // @ts-ignore
    return window.customConfig.fields[fieldKey];
  }
  return {};
};
