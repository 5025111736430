import Vue from 'vue';
import Vuex, {GetterTree, Module, MutationTree} from 'vuex';
import {StoreState} from '@/configurator/store/index';
import {Nullable} from '@/common/utils/types';

Vue.use(Vuex);

const namespaced: boolean = false;

export interface CoreState {
  label: string;
  componentHasChildren: boolean;
  catalogLabel: Nullable<string>;
}

export enum CORE_STATE_MUTATIONS {
  SET_LABEL = 'setLabel',
  SET_COMPONENT_HAS_CHILDREN = 'setComponentHasChildren',
  SET_CATALOG_LABEL = 'SET_CATALOG_LABEL',
}

export enum CORE_STATE_ACTIONS {
  SET_LABEL = 'setLabel',
  SET_COMPONENT_HAS_CHILDREN = 'setComponentHasChildren',
}

const initialState: CoreState = {
  label: '',
  catalogLabel: null,
  componentHasChildren: false,
};

const getters: GetterTree<CoreState, StoreState> = {
  safeLabel(state: CoreState): string {
    const safeLabel = state.label || '';
    return safeLabel;
  },
};

// const actions: ActionTree<CoreState, StoreState> = {
//   [CORE_STATE_ACTIONS.SET_LABEL]({commit}, label): any {
//     commit(CORE_STATE_ACTIONS.SET_LABEL, label);
//   },
// };

const mutations: MutationTree<CoreState> = {
  [CORE_STATE_MUTATIONS.SET_LABEL](state: CoreState, label: string) {
    state.label = label;
  },
  [CORE_STATE_MUTATIONS.SET_CATALOG_LABEL](state: CoreState, label: string) {
    state.catalogLabel = label;
  },
  [CORE_STATE_MUTATIONS.SET_COMPONENT_HAS_CHILDREN](state: CoreState, componentHasChildren: boolean) {
    state.componentHasChildren = componentHasChildren;
  },
};

export const coreState: Module<CoreState, StoreState> = {
  namespaced,
  state: initialState,
  getters,
  // actions,
  mutations,
};
