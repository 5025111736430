import {GlobalInitDataDefinition, InitDataDefinition, FeatureFlags} from '@roomle/web-sdk/lib/definitions/common-core/src/utils/shims';
import {RapiConfiguratorSettings, RapiId} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';

export enum UI_BUTTON {
  AR = 'ar',
  PARTLIST = 'partlist',
  MULTISELECT = 'multiselect',
  DIMENSIONS = 'dimensions',
  FULLSCREEN = 'fullscreen',
  RESETCAMERA = 'resetcamera',
  RENDERIMAGE = 'renderimage',
  ADDONS = 'addons',
  REQUESTPRODUCT = 'requestproduct',
  SAVEDRAFT = 'savedraft',
  STARTCONFIGURE = 'startconfigure',
  PAUSECONFIGURE = 'pauseconfigure',
  VARIANTS = 'variants',
  EXPORT_3D = 'export3d',
  ADDTOCART = 'ADDTOCART',
}

interface FeatureFlagsCustom extends FeatureFlags {
  realPartList?: boolean;
}

export interface VariantsMap {
  [key: string]: RapiId;
}

export interface UiInitData extends InitDataDefinition, GlobalInitDataDefinition {
  customConfig?: any;
  buttons?: {
    [UI_BUTTON.AR]?: boolean,
    [UI_BUTTON.PARTLIST]?: boolean,
    [UI_BUTTON.MULTISELECT]?: boolean,
    [UI_BUTTON.DIMENSIONS]?: boolean,
    [UI_BUTTON.FULLSCREEN]?: boolean,
    [UI_BUTTON.RESETCAMERA]?: boolean,
    [UI_BUTTON.RENDERIMAGE]?: boolean,
    [UI_BUTTON.ADDONS]?: boolean,
    [UI_BUTTON.REQUESTPRODUCT]?: boolean,
    [UI_BUTTON.SAVEDRAFT]?: boolean,
    [UI_BUTTON.STARTCONFIGURE]?: boolean,
    [UI_BUTTON.PAUSECONFIGURE]?: boolean,
    [UI_BUTTON.EXPORT_3D]?: boolean,
  };
  skin?: EmbeddingSkin;
  zIndex?: number;
  useLocalRoomle?: boolean;
  overrideServerUrl?: string;
  /**
   * @deprecated
   * for details see deeplink, the only change is that the placeholder is <CONF_ID> here instead of #CONFIGURATIONID#
   */
  shareUrl?: string;

  /**
   * Set this parameter to use your custom share url.
   * The provided url has to contain a configuration id parameter (`#CONFIGURATIONID#`) which will be replaced with the real
   * configuration id.
   * default: not set -> use Roomle short url service (linking to roomle.com domain)
   * usage: http://www.example.com/configurator?id=#CONFIGURATIONID#
   */
  deeplink?: string;

  /**
   * Define to which variants you can switch from which root component id. This is a simple JS object which is
   * used like a map, e.g.: { "usm:frame": "DB_Start_ohne_Element" } now if a configuration with root component
   * usm:frame is loaded all the items from the tag "DB_Start_ohne_Element" are now shown as variants
   */
  variants?: VariantsMap;
  /**
   * Indicates if we are currently running as viewer or configurator
   */
  viewer?: boolean; // will be removed after https://roomle.atlassian.net/browse/RML-44
  /**
   * if set to true you can load the configurator in an iframe.src but without any embedding lib
   * This is needed if you do not want to interact with the configurator and only want to use it
   * to view 3d content. Imagine it then as a <img> tag for 3d content
   */
  api?: boolean;
  /**
   * if this is set to true the "save draft" will be done by sending
   * an e-mail
   */
  emails?: boolean;
  /**
   * set this flag to true if you want to configure on small devices in landscape as well
   * this is relevant for things like PDC, where the configurator is embedded into a very
   * small div
   */
  mobileLandscape?: boolean;
  /**
   * set this flag to true if you want to group the part list by main components
   */
  groupPartList?: boolean;
  /**
   * @internal
   * this is an object which is for Roomle internal usage, we can set feature flags
   * so that we can ensure a smooth transition between different versions of our configurator
   */
  featureFlags?: FeatureFlagsCustom;
  /**
   * set this flag to false if the user did not give the consent to Analytics
   */
  gaConsent?: boolean;
  /**
   * override translations to change labels for some or all languages.
   * Example:
   *
   * ```
   *   "translations": {
   *     "en": {
   *       "params": {
   *         "request-product": "Add to cart",
   *       }
   *     }
   *   }
   * ```
   *
   * You can find all labels here: [Base.json](../../../ui/base.json)
   */
  translations?: any;
}

export interface EmbeddingSkin {
  'primary-color'?: string;
  'primary-color-in-rgb'?: string;
  'color-on-primary'?: string;
  'cta-color'?: string;
  'color-on-cta'?: string;
}

export interface ConfiguratorSettings extends RapiConfiguratorSettings {
  id: string;
  allowedHost: string;
  tenant: number;
  url: string;
  settings: UiInitData;
}
