import {RapiMaterial, RapiMaterialGroup, RapiTexture, RapiId, RapiAdditionalContent, RapiItem, RapiTagForUi} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {KernelPartListParameter, UiPossibleChildTag} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {CollectionViewElement} from '@/configurator/components/collection-view/-utils/types';
import {VariantsMap} from '@/configurator/embedding/types';
import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
// can not import RAPI_PATHS and RAPI_ADDITIONAL_CONTENTS because it's a const enum
// import {RAPI_PATHS, RAPI_ADDITIONAL_CONTENTS} from '@roomle/web-sdk/lib/definitions/common-core/src/rapi-access';

// @todo this should move into SDK
export interface RapiMaterialEmbeddedTextures extends RapiMaterial {
  embeddedTextures?: RapiTexture[];

  [key: string]: any;
}

export const getAllMaterials = (groups: RapiMaterialGroup[] = []): RapiMaterialEmbeddedTextures[] => groups.reduce((acc, group) => {
  acc.push(...group.materials);
  return acc;
}, [] as RapiMaterialEmbeddedTextures[]);

export const mapAddons = (addons: UiPossibleChildTag[]): CollectionViewElement[] => {
  return addons.map((addonTag) => ({
    label: addonTag.label,
    key: 'addon_' + addonTag.id,
    payload: addonTag,
    type: 'addon',
  })) as CollectionViewElement[];
};

export const mapVariants = (tag: RapiTagForUi): CollectionViewElement[] => {
  if (!tag.items || !tag.items.length) {
    return [];
  }

  return [{
    label: tag.label,
    key: 'variant_' + tag.id,
    payload: tag,
    type: 'variant',
  }] as CollectionViewElement[];
};

export const filterAndMapAddons = (addons: UiPossibleChildTag[], selectedGroup: string | undefined): CollectionViewElement[] => {
  if (!selectedGroup) {
    return [];
  }
  const filteredAddons = addons
    .map((addon) => {
      const result = {...addon};
      result.possibleChildren = result.possibleChildren.filter((possibleChild) => possibleChild.group === selectedGroup);
      return result;
    })
    .filter((addon) => addon.possibleChildren.length > 0);
  return mapAddons(filteredAddons);
};

export const addonsHaveGroups = (addons: UiPossibleChildTag[]): boolean => {
  if (!addons.length) {
    return false;
  }
  for (const possibleChildTag of addons) {
    for (const possibleChild of possibleChildTag.possibleChildren) {
      if (possibleChild.group) {
        return true;
      }
    }
  }
  return false;
};

export const extractRootComponentId = (id: string) => {
  if (!id || typeof id !== 'string') {
    return;
  }
  const separator = ':';
  return id.split(separator).slice(0, 2).join(separator);
};

export const getCurrentVariants = async (api: RoomleConfigurator, id: string, variantChangeMap: VariantsMap = {}) => {
  const rootComponentId = extractRootComponentId(id);
  if (!rootComponentId) {
    return;
  }
  for (const variantRootCompopnentId in variantChangeMap) {
    if (variantRootCompopnentId === rootComponentId) {
      const tagId = variantChangeMap[variantRootCompopnentId];
      // @ts-ignore -- because we can not import RAPI_PATHS and therefore there is a type error
      const tag = await api.getTagById(tagId, {include: ['items']});
      const additionalContents = await api.getAdditionalContentsOf(tag.items);
      const additionalContentsMap = new Map<RapiId, RapiAdditionalContent>();
      additionalContents.forEach((additionalContent: RapiAdditionalContent) => {
        // needed to use raw ID because RAPI_ADDITIONAL_CONTENTS can not imported because it's a const enum
        if (additionalContent.type === 6) {
          additionalContentsMap.set(additionalContent.itemId, additionalContent);
        }
      });
      tag.items.forEach((item: RapiItem) => item.sketch = (additionalContentsMap.has(item.id) ? additionalContentsMap.get(item.id)!.content : item.perspectiveImage));
      return tag;
    }
  }
};

export const prepareParametersForUi = (parameters: KernelPartListParameter[]) => {
  return parameters
    .filter((part) => !!part.label)
    .sort((p1, p2) => (p1.sort ? p1.sort : 0) - (p2.sort ? p2.sort : 0));
};
