import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
import ConfiguratorUiCallbacks from '@roomle/web-sdk/lib/definitions/configurator-core/src/services/configurator-ui-callback';
import {ExposedCallbacks} from '@/configurator/embedding/exposed-callbacks';
import {LoadResponse, SdkConnector} from '@/configurator/business-logic/sdk-connector';
import {EmbeddingCommand, MessageExecutionResult, MessageHandler} from '@/configurator/embedding/message-handler';
import {Store} from 'vuex';
import {StoreState} from '@/configurator/store';
import {UI_STATE_ACTIONS} from '@/configurator/store/ui-state';
import {getMethodNames, HANDSHAKE_MESSAGES, NAMESPACE, NAMESPACE_SEPARATOR} from '@/configurator/embedding/utils';
import {startConfiguring, triggerRequestProduct} from '@/configurator/embedding/helper';
import {Nullable} from '@/common/utils/types';
import {Analytics} from '@/common/plugins/analytics';
import {ExposedAnalyticsCallbacks} from '@/configurator/embedding/exposed-analytics-callbacks';
import RoomleGLBViewer from '@roomle/web-sdk/lib/definitions/glb-viewer-core/src/roomle-glb-viewer';

// import RoomleGLBViewer from '@roomle/web-sdk/lib/definitions/glb-viewer-core/src/roomle-glb-viewer';

export class ExposedApi {
  private _sdk: RoomleConfigurator | RoomleGLBViewer;
  private _exposedCallbacks: ExposedCallbacks;
  private _sdkConnector: SdkConnector;
  private _store: Store<StoreState>;
  private _analytics: Analytics;
  private _exposedAnalyticsCallbacks: ExposedAnalyticsCallbacks;
  private _isWebsiteReady = false;

  constructor(
    sdkConnector: SdkConnector,
    messageHandler: MessageHandler | null,
    sdk: RoomleConfigurator | RoomleGLBViewer,
    exposedCallbacks: ExposedCallbacks,
    store: Store<StoreState>,
    analytics: Analytics,
  ) {
    this._sdk = sdk;
    this._exposedCallbacks = exposedCallbacks;
    this._sdkConnector = sdkConnector;
    this._store = store;
    this._analytics = analytics;
    this._exposedAnalyticsCallbacks = new ExposedAnalyticsCallbacks();

    const availableUiCallbacks = getMethodNames(this._exposedCallbacks, NAMESPACE.UI, true);
    const availableUiMethods = getMethodNames(Object.getPrototypeOf(this), NAMESPACE.UI);
    const availableSdkMethods = getMethodNames(Object.getPrototypeOf(this._sdk), NAMESPACE.SDK);
    const availableSdkCallbacks = getMethodNames(this._sdk.callbacks, NAMESPACE.SDK, true);
    const availableAnalyticsCallbacks = getMethodNames(this._exposedAnalyticsCallbacks, NAMESPACE.ANALYTICS, true);

    availableSdkCallbacks.forEach((callbackName) => {
      const fullname = callbackName.split(NAMESPACE_SEPARATOR);
      const callbackShortName = fullname.pop();
      // tslint:disable-next-line:only-arrow-functions
      sdkConnector.addCallback(callbackShortName as keyof ConfiguratorUiCallbacks, function () {
        return messageHandler?.sendMessage(callbackName, [...arguments]);
      }, true);
    });

    availableUiCallbacks.forEach((callbackName) => {
      const fullname = callbackName.split(NAMESPACE_SEPARATOR);
      const callbackShortName = fullname.pop() as keyof ExposedCallbacks;
      // tslint:disable-next-line:only-arrow-functions
      this._exposedCallbacks[callbackShortName] = function () {
        return messageHandler?.sendMessage(callbackName, [...arguments]);
      };
    });

    availableAnalyticsCallbacks.forEach((callbackName: any) => {
      const fullname = callbackName.split(NAMESPACE_SEPARATOR);
      const callbackShortName = fullname.pop() as keyof ExposedAnalyticsCallbacks;
      this._exposedAnalyticsCallbacks[callbackShortName] = (...args: any[]) => {
        if (!this._isWebsiteReady) {
          return;
        }
        return messageHandler?.sendMessage(callbackName, [...args]);
      };
    });

    this._analytics?.ga?.addCallback((...args: any[]) => {
      this._exposedAnalyticsCallbacks.onGATracking(...args);
    });

    this._forwardSdkCallbacks();
    // No need for await here, we just send the message and wait for the response
    // message to receive, therefore we just set "setMessageExecution"
    messageHandler?.sendMessage(HANDSHAKE_MESSAGES.SETUP, [{
      methods: [...availableSdkMethods, ...availableUiMethods],
      callbacks: [...availableSdkCallbacks, ...availableUiCallbacks, ...availableAnalyticsCallbacks],
    }]);
    messageHandler?.setMessageExecution(this._handleMessage.bind(this));

    (window as any).RubensAPI = this;
  }

  public get callbacks(): ExposedCallbacks {
    return this._exposedCallbacks;
  }

  /**
   * call this method to load the object you want into the 3d Scene
   * @param id database ID of the object you want to load
   */
  public loadObject(id: string): Promise<Nullable<LoadResponse>> {
    if (!id) {
      return Promise.reject('Please provide a ID for loadObject');
    }
    return this._sdkConnector.loadObject(id);
  }

  /**
   * call this method to load the configuration string you want into the 3d Scene
   * @param configurationString string of the configuration, starts with "{" and ends with "}"
   */
  public loadConfigurationString(configurationString: string): Promise<Nullable<LoadResponse>> {
    if (!configurationString.startsWith('{')) {
      return Promise.reject('Please provide a configuration-string (starts with "{" and ends with "}"), for IDs please use loadObject');
    }
    return this._sdkConnector.loadConfigurationString(configurationString);
  }

  /**
   * set the price for the UI to show
   * most likely needed when implementing your own price service
   *
   * @param currencySymbol
   * @param price
   */
  public setPrice(currencySymbol: string, price: number) {
    this._sdkConnector.setPrice(currencySymbol, price);
  }

  /**
   * This method can be used to start configuration when
   * 3d scene is only initialized as viewer first, the
   * configurator is automatically instantiated as viewer
   * if the container where the configurator is placed is
   * smaller than 1024px (this breakpoint could change in future)
   * so do not rely on this exact pixel setting
   */
  public startConfiguring() {
    startConfiguring(this._store, this._exposedCallbacks);
  }

  /**
   * This method can be used to pause configuration
   * If the configurator was called in view-only mode
   * this method triggers the onBackToWebsite callback.
   * To know when the view-only mode is activated see the
   * description of the startConfiguring method
   */
  public pauseConfiguring() {
    if (!this._store.state.uiState.isDesktop) {
      this._exposedCallbacks.onBackToWebsite();
      this._store.dispatch(UI_STATE_ACTIONS.PAUSE_CONFIGURING);
    }
  }

  /**
   * call this method to trigger the onRequestProduct event from
   * outside of the iframe. When this method is called the same
   * process is kicked off as if the user would have clicked on
   * request product. Therefore you can just use the same callback
   * to react on the response
   */
  public triggerRequestProduct(): Promise<void> {
    return triggerRequestProduct(this._exposedCallbacks, this._sdkConnector, this._store, this._analytics);
  }

  /**
   * call this method if consent of Google Analytics is given later and not already in init-data on boot
   */
  public giveGaConsent() {
    this._analytics.ga.history.forEach((functionArgs: IArguments) => {
      const args = [].slice.call(functionArgs);
      this._exposedAnalyticsCallbacks.onGATracking(...args);
    });
    this._sdkConnector.giveGaConsent();
  }

  private _websiteReady() {
    this._isWebsiteReady = true;
    this._exposedCallbacks.onResize(this._store.state.uiState.isDesktop);
    const consent = this._store.state.uiState.initData?.gaConsent;
    // currently webshop has to opt-out, we need to discuss how we want
    // this in the future, see: https://roomle.atlassian.net/browse/RML-609
    if (consent || typeof consent !== 'boolean') {
      this.giveGaConsent();
    }
  }

  private _handleMessage = ({message, args}: EmbeddingCommand, _event: MessageEvent): MessageExecutionResult => {

    if (message === HANDSHAKE_MESSAGES.WEBSITE_READY) {
      return new Promise((resolve) => {
        this._websiteReady();
        resolve();
      });
    }

    const namespaces = message.split(NAMESPACE_SEPARATOR);
    const object = namespaces[0];
    const methodName = namespaces[1];

    const instance: any = (object === NAMESPACE.SDK) ? this._sdk : this;

    if (!instance[methodName]) {
      return Promise.reject({error: 'The method "' + message + '" is unknown'});
    }
    const result = instance[methodName](...args);
    if (result instanceof Promise) {
      return result;
    } else {
      return Promise.resolve(result);
    }
  }

  private _forwardSdkCallbacks() {
    this._sdkConnector?.addCallback('onPartListUpdate', (partList, hash) => {
      /**
       * BREAKING SINCE VERSION 3.12.0-alpha.1 (as of 2020-11-05)
       * we return now the whole partlist object which we get from
       * the Core and not only the fullList
       * Link: https://gitlab.com/roomle/web/roomle-ui/-/merge_requests/303
       */
      if (this._store.state.uiState.initData?.featureFlags?.realPartList) {
        this.callbacks.onPartListUpdate(partList, hash);
      } else {
        // need to use "as any" to be compliant with old versions of embedding
        this.callbacks.onPartListUpdate(partList.fullList as any, hash);
      }
    }, true);
  }

}
