const initialLoadMarkKey = 'initial-load';
const regularLoadMarkKey = 'regular-load';

const ensureMark = () => {
  // depending on the security settings (thanks to melt down and spectre)
  // performance functions are not always there...
  if (!performance.mark) {
    performance.mark = () => undefined;
  }
  if (!performance.getEntriesByName) {
    (performance as any).getEntriesByName = () => [{startTime: 0}, {startTime: Number.MAX_SAFE_INTEGER}];
  }
  if (!performance.clearMarks) {
    (performance as any).clearMarks = () => undefined;
  }
};

export const addLoadMark = () => {
  ensureMark();
  performance.mark(regularLoadMarkKey);
};

export const calcTiming = () => {
  ensureMark();
  const entries = performance.getEntriesByName(initialLoadMarkKey);
  const timingMarkKey = (entries.length > 0) ? initialLoadMarkKey : regularLoadMarkKey;
  performance.mark(timingMarkKey);
  const [start, end] = performance.getEntriesByName(timingMarkKey);
  // we do not want to clutter the output during tests
  if (process.env.NODE_ENV !== 'test') {
    if (!start || !end) {
      console.warn('TIMING: ', 'Can not calculate timing, please check why');
    } else {
      console.log('TIMING: ', end.startTime - start.startTime, timingMarkKey, process.env.NODE_ENV);
    }
  }
  performance.clearMarks(initialLoadMarkKey);
  performance.clearMarks(regularLoadMarkKey);
};
