import {getFieldCustomConfigByKey} from './custom-config';

export const sortPartListGroups = (store: any) => {
  let mainComponents;

  if (store.state.uiState.initData.groupPartList) {
    mainComponents = store.state.coreData.partList.perMainComponent;
  }
  const {originPart, fullList, perMainComponent} = store.state.coreData.partList;
  const originPartToUse = JSON.parse(JSON.stringify(originPart));
  mainComponents = [{originPart: originPartToUse, fullList, perMainComponent}];

  let groupedFullList: any = {};

  mainComponents[0].fullList.forEach((item: any) => {
    const groupLabel = item.parameters.filter((param: any) => param.key === 'partListGroup')[0]?.value;
    if (groupLabel) {
      if (groupedFullList[groupLabel]) {
        groupedFullList[groupLabel].push({...item, group: groupLabel});
      } else {
        groupedFullList[groupLabel] = [{...item, group: groupLabel}];
      }
    }
  });

  const sortGroupsArray = ['boards', 'furniture', 'basin', 'tap', 'mirror'];

  groupedFullList = Object.entries(groupedFullList).sort((a, b) => {
    if (sortGroupsArray.indexOf(a[0]) > sortGroupsArray.indexOf(b[0])) {
      return 1;
    } else if (sortGroupsArray.indexOf(a[0]) < sortGroupsArray.indexOf(b[0])) {
      return -1;
    }
    return 0;
  });

  groupedFullList.forEach((group: any) => {
    group[1].sort((a: any, b: any) => {
    if (a.parameters.filter((param: any) => param.key === 'partListSort')[0]?.value > b.parameters.filter((param: any) => param.key === 'partListSort')[0]?.value) {
      return 1;
    } else if (a.parameters.filter((param: any) => param.key === 'partListSort')[0]?.value < b.parameters.filter((param: any) => param.key === 'partListSort')[0]?.value) {
      return -1;
    }
    return 0;
    });
  });

  return groupedFullList.map((group: any) => ({groupValue: group[0], parts: group[1]}));
};

export const createGroupParameter = async (groups: any, store: any, stringStart: string | null) => {
  const getParamInfo = (parameter: any) => {
    const configParam = getFieldCustomConfigByKey(parameter.key);
    if (configParam) {
      return {
        active: configParam?.active,
        label: configParam?.label || parameter.label,
        value: parameter.validValues.filter((validValue: any) => validValue.value === parameter.value)[0]?.label || parameter.valueLabel,
      };
    } else {
      return null;
    }
  };

  const sortedParams = (params: any) => {
    const configParams: any[] = [];
    params?.forEach((param: any) => configParams.push({param, order: getFieldCustomConfigByKey(param.key)?.order}));
    configParams.sort((a, b) => a?.order > b?.order ? 1 : -1);
    return configParams.map((configParam) => configParam.param);
  };

  for (const group of groups) {
    if (group.groupValue === 'boards' && store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpBoard' || stateGroup.key === 'grpBoards').length) {
      group.params = store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpBoard' || stateGroup.key === 'grpBoards')[0].parameters;
    }
    if (group.groupValue === 'furniture' && store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpFurniture').length) {
      group.params = store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpFurniture')[0].parameters;
    }
    if (group.groupValue === 'basin' && store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpBasin').length) {
      group.params = store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpBasin')[0].parameters;
    }
    if (group.groupValue === 'tap' && store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpTap').length) {
      group.params = store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpTap')[0].parameters;
    }
    if (group.groupValue === 'mirror' && store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpMirrors').length) {
      group.params = store.state.coreData.groups.filter((stateGroup: any) => stateGroup.key === 'grpMirrors')[0].parameters;
    }

    group.params = sortedParams(group.params);

    const newParams = [];

    for (const param of group.params) {
      let colorLabel = null;
      const colorGroups = await (param as any).groups;
      if (colorGroups) {
        let foundKey = null;
        colorGroups.forEach((colorGroup: any) => {
          foundKey = colorGroup.materials.filter((material: any) => material.id === (param as any).value)[0];
          if (foundKey) {
            colorLabel = foundKey?.label;
          }
        });
      }

      if (
        getParamInfo(param)
        && getParamInfo(param)?.active
        && !getParamInfo(param)?.value.toUpperCase().includes('EMPTY')
      ) {
        newParams.push(`${getParamInfo(param)?.label}: ${colorLabel || getParamInfo(param)?.value}`);
      }
    }

    group.params = `${stringStart ? `${stringStart}: ` : ''}${newParams.join(', ')}`;
  }

  return groups;
};
