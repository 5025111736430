// This separate file is needed because if we put something vue related into
// utils.ts the whole vue lib is included into the embedding.js

import {UI_STATE_MUTATIONS, UI_STATE_ACTIONS} from '@/configurator/store/ui-state';
import {ExposedCallbacks} from '@/configurator/embedding/exposed-callbacks';
import {Store} from 'vuex';
import {StoreState} from '@/configurator/store';
import {SdkConnector} from '@/configurator/business-logic/sdk-connector';
import {showLoadingScreen, removeLoadingScreen} from '@/common/utils/dom';
import {Analytics} from '@/common/plugins/analytics';
import {UI_BUTTON} from '@/configurator/embedding/types';
import {GA_CATEGORY} from '@/common/utils/google-analytics';

export const startConfiguring = (store: Store<StoreState>, exposedCallbacks: ExposedCallbacks) => {
  store.commit(UI_STATE_MUTATIONS.SET_IS_EXPANDED_FOR_CONFIGURE, !store.state.uiState.isDesktop);
  if (!store.state.uiState.isDesktop) {
    exposedCallbacks.onUseFullPage();
    store.dispatch(UI_STATE_ACTIONS.START_CONFIGURING);
  }
};

export const triggerRequestProduct = async (embeddingCallbacks: ExposedCallbacks, sdkConnector: SdkConnector, store: Store<StoreState>, analytics: Analytics) => {
  showLoadingScreen();
  embeddingCallbacks.onButtonClicked(UI_BUTTON.REQUESTPRODUCT);
  const configurator = await sdkConnector.api;
  const image = await configurator.preparePerspectiveImage();
  const {currencySymbol, price} = store.state.coreData;
  const {catalogLabel, label} = store.state.coreState;
  const configuration = await configurator.saveCurrentConfiguration();
  const pricing = {currencySymbol, price};
  const labels = {catalogLabel, label};
  const storedPartList = store.state.coreData.partList;
  /**
   * BREAKING SINCE VERSION 3.12.0-alpha.1 (as of 2020-11-05)
   * we return now the whole partlist object which we get from
   * the Core and not only the fullList
   * Link: https://gitlab.com/roomle/web/roomle-ui/-/merge_requests/303
   */
  const useRealPartList = store.state.uiState.initData?.featureFlags?.realPartList;
  const partList = useRealPartList ? storedPartList : (storedPartList.fullList as any);
  embeddingCallbacks.onRequestProduct(configuration.id, image, partList, pricing, labels, configuration);
  analytics.ga.trackEvent('RequestProduct', GA_CATEGORY.INTERACTION, configuration.id);
  removeLoadingScreen();
};
