import Vue from 'vue';
import Vuex, {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {StoreState} from '@/configurator/store/index';
import {KernelParameterGroup, KernelPartList, UiKernelParameter, UiPossibleChildTag} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {Nullable} from '@/common/utils/types';
import {KernelBoundsFormatted, round, scriptInfo, formatPrice} from '@/common/utils/helper';

Vue.use(Vuex);

const namespaced: boolean = false;

export interface ParameterGroup extends KernelParameterGroup {
  parameters: UiKernelParameter[];
}

export interface CoreData {
  groups: ParameterGroup[];
  parameters: UiKernelParameter[];
  addons: UiPossibleChildTag[];
  partList: KernelPartList;
  bounds: Nullable<KernelBoundsFormatted>;
  currencySymbol: string;
  price: Nullable<number>;
  taxRate: Nullable<number>;
  isShopAvailable: boolean;
  articles: Nullable<any>;
  attributes: Nullable<any>;
  priceChanged: boolean;
  count: number;
  loggedInGroup: boolean | 'sales' | 'b2b';
  scrollState: string;
  undoRedoTrigger: boolean;
  isEditing: boolean;
  parentUrl: string;
  notFound: Nullable<any>;
}

export enum CORE_DATA_MUTATIONS {
  UPDATE_GROUPS = 'updateGroups',
  UPDATE_PARAMETERS = 'updateParameters',
  UPDATE_ADDONS = 'updateAddons',
  UPDATE_PART_LIST = 'UPDATE_PART_LIST',
  UPDATE_BOUNDS = 'UPDATE_BOUNDS',
  UPDATE_CURRENCY = 'UPDATE_CURRENCY',
  UPDATE_TAX_RATE = 'UPDATE_TAX_RATE',
  UPDATE_IS_SHOP_AVAILABLE = 'UPDATE_IS_SHOP_AVAILABLE',
  UPDATE_PRICE_VALUE = 'UPDATE_PRICE_VALUE',
  UPDATE_ARTICLES = 'UPDATE_ARTICLES',
  UPDATE_ATTRIBUTES = 'UPDATE_ATTRIBUTES',
  UPDATE_LOGGED_IN_GROUP = 'UPDATE_LOGGED_IN_GROUP',
  UPDATE_INTERACTION_SCROLLSTATE = 'UPDATE_INTERACTION_SCROLLSTATE',
  UPDATE_UNDO_REDO_TRIGGER = 'UPDATE_UNDO_REDO_TRIGGER',
  UPDATE_IS_EDITING = 'UPDATE_IS_EDITING',
  UPDATE_PARENT_URL = 'UPDATE_PARENT_URL',
  UPDATE_NOT_FOUND = 'UPDATE_NOT_FOUND',
}

export enum CORE_DATA_ACTIONS {
  UPDATE_PRICE = 'UPDATE_PRICE',
  UPDATE_ARTICLES = 'UPDATE_ARTICLES',
  UPDATE_ATTRIBUTES= 'UPDATE_ATTRIBUTES',
  UPDATE_TAX_RATE= 'UPDATE_TAX_RATE',
  UPDATE_IS_SHOP_AVAILABLE= 'UPDATE_IS_SHOP_AVAILABLE',
}

export enum CORE_DATA_GETTERS {
  HAS_NON_GROUP_ADDONS = 'hasNonGroupAddons',
  CURRENT_PRICE = 'currentPrice',
  ARTICLES = 'articles',
  TAX_RATE = 'taxRate',
  IS_SHOP_AVAILABLE = 'isShopAvailable',
  ATTRIBUTES = 'attributes',
  LOGGED_IN_GROUP = 'loggedInGroup',
  INTERACTION_SCROLLSTATE = 'scrollState',
  UNDO_REDO_TRIGGER = 'undoRedoTrigger',
  IS_EDITING = 'isEditing',
}

const initialState: CoreData = {
  groups: [],
  parameters: [],
  addons: [],
  partList: {fullList: [], originPart: {} as any, perMainComponent: []},
  bounds: null,
  currencySymbol: '',
  price: null,
  taxRate: null,
  isShopAvailable: true,
  articles: [],
  attributes: [],
  priceChanged: false,
  count: 1,
  loggedInGroup: false,
  scrollState: 'top',
  undoRedoTrigger: false,
  isEditing: false,
  parentUrl: '',
  notFound: null,
};

const getters: GetterTree<CoreData, StoreState> = {
  [CORE_DATA_GETTERS.CURRENT_PRICE](state: CoreData) {
    const {currencySymbol, price} = state;
    return formatPrice(currencySymbol, price);
  },
  [CORE_DATA_GETTERS.HAS_NON_GROUP_ADDONS](state: CoreData, _getters, rootState) {
    if (!state.addons || !state.addons.length) {
      return false;
    }

    // check if every addon is in a group
    for (const pct of state.addons) {
      for (const pc of pct.possibleChildren) {
        if (!pc.group) {
          if (rootState.uiState.initData?.debug) {
            scriptInfo('possible child has no group', pc);
          }
          return true;
        }
      }
    }
    return false;
  },
  [CORE_DATA_GETTERS.ARTICLES](state: CoreData) {
    return state.articles;
  },
  [CORE_DATA_GETTERS.TAX_RATE](state: CoreData) {
    return state.taxRate;
  },
  [CORE_DATA_GETTERS.IS_SHOP_AVAILABLE](state: CoreData) {
    return state.isShopAvailable;
  },
  [CORE_DATA_GETTERS.ATTRIBUTES](state: CoreData) {
    return state.attributes;
  },
  [CORE_DATA_GETTERS.LOGGED_IN_GROUP](state: CoreData) {
    return state.loggedInGroup;
  },
  [CORE_DATA_GETTERS.INTERACTION_SCROLLSTATE](state: CoreData) {
    return state.scrollState;
  },
  [CORE_DATA_GETTERS.UNDO_REDO_TRIGGER](state: CoreData) {
    return state.undoRedoTrigger;
  },
  [CORE_DATA_GETTERS.IS_EDITING](state: CoreData) {
    return state.isEditing;
  },
};

const mutations: MutationTree<CoreData> = {
  [CORE_DATA_MUTATIONS.UPDATE_GROUPS](state: CoreData, groups: ParameterGroup[]) {
    state.groups = groups;
  },
  [CORE_DATA_MUTATIONS.UPDATE_PARAMETERS](state: CoreData, parameters: UiKernelParameter[]) {
    state.parameters = parameters;
  },
  [CORE_DATA_MUTATIONS.UPDATE_ADDONS](state: CoreData, addons: UiPossibleChildTag[]) {
    state.addons = addons;
  },
  [CORE_DATA_MUTATIONS.UPDATE_PART_LIST](state: CoreData, partList: KernelPartList) {
    state.partList = partList;
  },
  [CORE_DATA_MUTATIONS.UPDATE_BOUNDS](state: CoreData, bounds: KernelBoundsFormatted) {
    state.bounds = bounds;
  },
  [CORE_DATA_MUTATIONS.UPDATE_CURRENCY](state: CoreData, currencySymbol: string) {
    state.currencySymbol = currencySymbol;
  },
  [CORE_DATA_MUTATIONS.UPDATE_TAX_RATE](state: CoreData, taxRate: number) {
    state.taxRate = taxRate;
  },
  [CORE_DATA_MUTATIONS.UPDATE_IS_SHOP_AVAILABLE](state: CoreData, isShopAvailable: boolean) {
    state.isShopAvailable = isShopAvailable;
  },
  [CORE_DATA_MUTATIONS.UPDATE_PRICE_VALUE](state: CoreData, price: number) {
    if (state.price && state.price !== round(price, 2)) {
      state.priceChanged = true;
    }
    state.price = round(price, 2); // @todo I think SDK should do the rounding
  },
  [CORE_DATA_MUTATIONS.UPDATE_ARTICLES](state: CoreData, articles: any) {
    state.articles = articles;
  },
  [CORE_DATA_MUTATIONS.UPDATE_ATTRIBUTES](state: CoreData, attributes: any) {
    state.attributes = attributes;
  },
  [CORE_DATA_MUTATIONS.UPDATE_LOGGED_IN_GROUP](state: CoreData, loggedInGroup: boolean | 'sales' | 'b2b') {
    state.loggedInGroup = loggedInGroup;
  },
  [CORE_DATA_MUTATIONS.UPDATE_INTERACTION_SCROLLSTATE](state: CoreData, scrollState: string) {
    state.scrollState = scrollState;
  },
  [CORE_DATA_MUTATIONS.UPDATE_UNDO_REDO_TRIGGER](state: CoreData, undoRedoTrigger: boolean) {
    state.undoRedoTrigger = undoRedoTrigger;
  },
  [CORE_DATA_MUTATIONS.UPDATE_IS_EDITING](state: CoreData, isEditing: boolean) {
    state.isEditing = isEditing;
  },
  [CORE_DATA_MUTATIONS.UPDATE_PARENT_URL](state: CoreData, parentUrl: string) {
    state.parentUrl = parentUrl;
  },
  [CORE_DATA_MUTATIONS.UPDATE_NOT_FOUND](state: CoreData, notFound: any) {
    state.notFound = notFound;
  },
};

const actions: ActionTree<CoreData, StoreState> = {
  [CORE_DATA_ACTIONS.UPDATE_PRICE]({commit}, {currencySymbol, price}: {currencySymbol: string, price: number}): void {
    commit(CORE_DATA_MUTATIONS.UPDATE_CURRENCY, currencySymbol);
    commit(CORE_DATA_MUTATIONS.UPDATE_PRICE_VALUE, price);
  },
  [CORE_DATA_ACTIONS.UPDATE_ARTICLES]({commit}, {articles}: {articles: any}): void {
    commit(CORE_DATA_MUTATIONS.UPDATE_ARTICLES, articles);
  },
  [CORE_DATA_ACTIONS.UPDATE_ATTRIBUTES]({commit}, {attributes}: {attributes: any}): void {
    commit(CORE_DATA_MUTATIONS.UPDATE_ATTRIBUTES, attributes);
  },
  [CORE_DATA_ACTIONS.UPDATE_TAX_RATE]({commit}, taxRate: number): void {
    commit(CORE_DATA_MUTATIONS.UPDATE_TAX_RATE, taxRate);
  },
  [CORE_DATA_ACTIONS.UPDATE_IS_SHOP_AVAILABLE]({commit}, isShopAvailable: boolean): void {
    commit(CORE_DATA_MUTATIONS.UPDATE_IS_SHOP_AVAILABLE, isShopAvailable);
  },
};

export const coreData: Module<CoreData, StoreState> = {
  namespaced,
  state: initialState,
  getters,
  actions,
  mutations,
};
