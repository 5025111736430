export class ExposedAnalyticsCallbacks {
  /**
   * Is called whenever something is sent to google analytics
   * Possible parameters are everything which is possible in gtag.js
   * If you need further details about gtag.js: https://developers.google.com/gtagjs
   * Currently we use Google Analytics in combination with gtag.js so
   * you can intercept events and send them to your own analytics tool
   */
  public onGATracking = (..._args: any[]): void => void 0;

}
